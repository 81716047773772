import { authHeader } from "../_helpers";
import { userService } from "."
import {defineConfig} from '../config'
import FileSaver from 'file-saver';

export const doohService = {
  getApiPathDooh,
  getHistoriesByIds,
  getDistinctByFieldFiltered,
  getDoohFields,
  getAllDooh,
  normalizeAsset,
  findByCenter,
  reversePostalCodeAsset,
  getCountAssetWithoutPostalCode,
  getIdKinFrameDistinctByLocation
};

const {apiUrl, apiVer} = defineConfig.DOOH_SERVICE

function getApiPathDooh(){
  return (apiUrl+apiVer)
}


function getDistinctByFieldFiltered(field, filters, page) {
  const url = apiUrl+apiVer+"/dooh/get/filters";

  const body = {
    "current": field,
    "history": filters,
    "pageable": {
      "page": page,
      "perPage": 250
    }
  }

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getHistoriesByIds(ids){
  const url = apiUrl+apiVer+"/dooh/get/historiesbyids";

  const body = { ids }

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function findByCenter(type = "radius", searchFilters){
  const url = apiUrl+apiVer+"/dooh/find/" + type

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(searchFilters)
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function getDoohFields() {
  const url = apiUrl+apiVer+"/dooh/get/fields";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllDooh() {
  const url = apiUrl+apiVer+"/dooh/get/aggregated";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function normalizeAsset() {
  const url = apiUrl+apiVer+"/dooh/normalize";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function reversePostalCodeAsset() {
  const url = apiUrl+apiVer+"/dooh/reverse/postal-code";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getCountAssetWithoutPostalCode() {
  const url = apiUrl+apiVer+"/dooh/get/without-postal-code/count";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function getIdKinFrameDistinctByLocation() {
  const url = apiUrl+apiVer+"/dooh/xls/export/distinct-by-location";
  return getFileFromUrl(url, null)
}

async function getFileFromUrl(url, fileName = null) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  try {
    const response = await fetch(url, requestOptions);
    console.log("scarico....");

    if (fileName === null) {
      const contentDisposition = response.headers.get('Content-Disposition');
      if (contentDisposition && contentDisposition.includes('filename=')) {
        fileName = contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
      } else {
        fileName = url.split('/').pop();
      }
    }

    const blob = await response.blob();
    FileSaver.saveAs(blob, fileName);
    return true;
  } catch (error) {
    console.error('Error fetching file:', error);
    throw error;
  }
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {

      switch(response.status){
        case 401:
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.reload(true);
          break;
        case 422:
          return data;
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
