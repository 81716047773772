import * as React from "react";
import { Button, Icon, Badge as BadgeTabler, Card } from "tabler-react"
import { Table, Badge, List, Checkbox, Dropdown, Button as ButtonAntd, Popover, Modal, Input, Space, Segmented, Tag, Spin} from 'antd';
import { DesktopOutlined, LoadingOutlined, FilterFilled, AudioOutlined, AudioMutedOutlined, SettingOutlined, FilterOutlined, DotChartOutlined, SearchOutlined, UnorderedListOutlined, PushpinOutlined, InfoCircleOutlined } from '@ant-design/icons';

import styles from 'antd/dist/antd.css';

import { doohService, audienceService } from '../_services'
import { toast } from "react-toastify";

import MapFilterSlider from '../components/Map/MapFilterSlider.react'

import HeatMapTableAudience from '../Planner/Planner.HeatMapTableAudience.react'
// import AudienceIndexMinMax from './AudienceIndexMinMax.react'


import kineticDB, {
  bulkcreate
} from '../database/DoohData.db';


import _ from 'lodash'
import MapPlannerReact from "../components/Map/MapPlanner.react";
import DetailMap from "../components/Map/DetailMap.Planner.react";

function sortNames(a, b) {
  if(a !== null && !isNaN(a)){
    a = a.toString()
  }
  if(b !== null &&  !isNaN(b)){
    b = b.toString()
  }
  var nameA = a && a.toUpperCase(); // ignora maiuscole e minuscole
  var nameB = b && b.toUpperCase(); // ignora maiuscole e minuscole
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // i nomi devono essere uguali
  return 0;
}


const expandable = { expandedRowRender: record => <p>Address: {record.site}</p> };
const title = () => '';
const showHeader = true;
const footer = () => '';
const pagination = { position: 'bottom' };

const DEFAULT_VALUES = {
  type: [],
  installation: [],
  region: [],
  district: [],
  city: [],
  postalCode: [],
  publisher: [],
  adsDuration: [],
  audio: [],
  mediaOwner:  [],
  dataType: [],
  videoType: [],
  videoFormat: [],
  orientation: [],
  screenFormat: [],
  screenType: [],
  cinema: [],
  gdo: [],
  note: []
  
}

const WIDTH_POIS_COLUMN = "200px";

const PROXIMITY_COLUMNS = [
  {
    field: 'pois',
    label: 'POI',
    visible: true
  },
  {
    field: 'coverage',
    label: 'Proximity',
    visible: true
  },
  {
    field: 'distance',
    label: 'Distance',
    visible: true
  },
]

const INIT_COLUMNS = [
  {
    field: 'type',
    label: 'Environment',
    visible: true
  },
  {
    field: 'installation',
    label: 'Type',
    visible: true
  },
  {
    field: 'publisher',
    label: 'Publisher',
    visible: true
  },
  {
    field: 'region',
    label: 'Region',
    visible: true
  },
  {
    field: 'district',
    label: 'District',
    visible: true
  },
  {
    field: 'city',
    label: 'City',
    visible: true
  },
  {
    field: 'postalCode',
    label: 'Postal code',
    visible: true
  },
  {
    field: 'audio',
    label: 'Audio',
    visible: true
  },
  {
    field: 'address',
    label: 'Address',
    visible: true
  },
  {
    field: 'adsDuration',
    label: 'Ads Duration',
    visible: true
  },
  {
    field: 'mediaOwner',
    label: 'Media Owner',
    visible: false
  },
  {
    field: 'dataType',
    label: 'Data Type',
    visible: false
  },
  {
    field: 'videoType',
    label: 'Video Type',
    visible: false
  },
  {
    field: 'videoFormat',
    label: 'Video Format',
    visible: false
  },
  {
    field: 'orientation',
    label: 'Orientation',
    visible: true
  },
  {
    field: 'index',
    label: 'Index',
    visible: true,
    disabled: true
  },
  {
    field: 'screenFormat',
    label: 'Screen Format',
    visible: false
  },
  {
    field: 'screenType',
    label: 'Screen Type',
    visible: false
  },
  {
    field: 'cinema',
    label: 'Cinema',
    visible: false
  },
  {
    field: 'gdo',
    label: 'GDO',
    visible: false
  },
  {
    field: 'note',
    label: 'Note',
    visible: false
  },
]

const db = kineticDB('kineticDB', {
  doohData: `++id`
});

class DoohPlanCart extends React.Component {
  state = {
    bordered: false,
    loading: true,
    resultMode: 'list',
    loadingAudience: false,
    loadingValues: false,
    pagination,
    size: 'default',
    expandable: undefined,
    showHeader,
    footer: undefined,
    rowSelection: {},
    selectedRowKeys: [],
    scroll: { y: 600 },
    hasData: true,
    tableLayout: undefined,
    top: 'none',
    bottom: 'bottomRight',

    isFormHidden: true,

    visibleDropdownColumns: false,
    activeColumns: INIT_COLUMNS,

    data: [],
    dataAll: [],
    filters: {},
    filtersArray: [],
    isEmptyFilter: true,
    // sortedInfo: null,
    cart: [],
    showCart: false,
    selectedMarker: null,
    isFiltered: false,

    audiences:[],
    audienceFilter: null,

    hideExcluded: true,
    indexMin: null,
    indexMax: null,
    lockFilter: null,
    lockFilterValues: null,

    values: DEFAULT_VALUES
  };

  constructor(props){
    super(props)

    this.handleToggleForm = this.handleToggleForm.bind(this)
    this.handleFilterDropdown = this.handleFilterDropdown.bind(this)
    this.getValuesByField = this.getValuesByField.bind(this)
    this.addToCart = this.addToCart.bind(this)
    this.resetSelected = this.resetSelected.bind(this)
    this.resetValues = this.resetValues.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.getColumnTemplate = this.getColumnTemplate.bind(this)
  }

  componentWillMount(){
    const {cart, data, readonly, isFiltered} = this.props

    if(!readonly && !isFiltered){
      this.getDooh()
    }
    else{
      this.setState({loading: false})
    }

    if(isFiltered){
      this.setState({
        activeColumns: [...PROXIMITY_COLUMNS, ...INIT_COLUMNS]
      })
    }

    this.setState({
      isFiltered,
      cart,
      data,
      dataAll: data
    })
  }

  componentDidMount(){
    this.loadAudiences();
  }
  

  componentWillReceiveProps(nextProps) {
    if(nextProps.data!==this.props.data){
      // Reset
      if(nextProps.data === null){
        this.setState({loading: true});
        this.getDooh();
        this.loadAudiences();
        return;
      }
      const uniqueData = _.uniqBy(nextProps.data, 'id');
      this.setState({
        data: uniqueData,
        dataAll: uniqueData,
      })
    }
    if(nextProps.cart !== this.props.cart){
      this.setState({
        cart: nextProps.cart,
      })
    }
    if(nextProps.isFiltered !== this.props.isFiltered){
      if(nextProps.isFiltered){
        this.setState({
          activeColumns: [...PROXIMITY_COLUMNS, ...INIT_COLUMNS],
          isFiltered: nextProps.isFiltered
        })
      }
      else{
        this.setState({
          activeColumns: INIT_COLUMNS,
          isFiltered: nextProps.isFiltered
        });
      }
    }
    if(nextProps.audienceFilter !== this.props.audienceFilter){
      this.setState({
        audienceFilter: nextProps.audienceFilter,
        loadingAudience: true
      }, () => setTimeout(this.getAudienceIndexes, 2000))
    }
  }

  strToCapitalize(str){
    return str.replace(/\b[a-zA-Z]/g, (match) => match.toUpperCase())
  }

  getDooh(){
    doohService.getAllDooh().then(
      result => {
        result.map((el, index) => {
          el.idKinFace = el.idKinFrame
          return el.key = el.id;
        });

        bulkcreate(db.doohData, result, true);
        this.setState({
          data: result,
          dataAll: result
        }, ()=> this.setState({loading: false}))
      },
      error => {
        toast.error('Error: ' + error)
      }
    )
  }

  getAudienceIndexes = () => {
    const {readonly} = this.props;
    const {dataAll, cart, audienceFilter} = this.state;
    if(typeof audienceFilter === "undefined" || audienceFilter === null){
      this.setState({
        loadingAudience: false
      });
      // TODO: Empty indexes
      return;
    }

    let ids = []
    if(readonly){
      ids = cart.map((d) => d.parentFrame || d.idKinFace)
      ids = _.uniq(ids)
      this.getIndexesAudienceByIdsCart(ids, audienceFilter.id)
    }
    else{
      ids = dataAll.map((d) => d.childrenIdKinFrame === null ? (d.idKinFace) : d.childrenIdKinFrame)
      ids = _.flatten(ids)

      this.getIndexesAudienceByIds(ids, audienceFilter.id)
    }
  }

  getIndexesAudienceByIds = (ids, audienceId) => {
    let {dataAll} = this.state

    if(!audienceId){
      const dataWithAudienceNull = dataAll.map(a => {
        a.index = null;
        return a;
      })
      this.setState({
        audiences: [],
        dataAll: dataWithAudienceNull,
        data: dataWithAudienceNull,
      })
      return null;
    }

    // this.getAudienceDetails(audienceId);

    audienceService.getIndexesAudienceByIds(ids, audienceId, 'dooh').then(
      result => {
        const dataWithAudience = dataAll.map(a => {
          if(a.childrenIdKinFrame === null){
            let audienceFound = result.find(r => (r.faceId === a.idKinFace) || (a.parentFrame !== null && r.faceId === a.parentFrame))
            a.index = typeof audienceFound !== 'undefined' ? audienceFound.index : null;
          }
          else{
            let audienceFound = result.filter(r => a.childrenIdKinFrame.includes(r.faceId))
            const indexesAud = audienceFound.map(aud => aud.index)
            const minIndex = _.min(indexesAud)
            const maxIndex = _.max(indexesAud)
            a.minIndex = minIndex
            a.maxIndex = maxIndex
          }
          return a;
        })

        this.setState({
          data: dataWithAudience,
          audiences: result,
          loadingAudience: false
        })
      },
      error => {
        toast.error('Error: ' + error);
        const dataWithAudienceNull = dataAll.map(a => {
          delete a.minIndex
          delete a.maxIndex
          a.index = null;
          return a;
        })
        this.setState({
          audiences: [],
          data: dataWithAudienceNull,
          dataAll: dataWithAudienceNull,
          loadingAudience: false
        })
      }
    )
  }

  getIndexesAudienceByIdsCart = (ids, audienceId) => {
    let {cart} = this.state

    if(!audienceId){
      const cartWithAudienceNull = cart.map(a => {
        a.index = null;
        return a;
      })
      this.setState({
        audiences: [],
        cart: cartWithAudienceNull,
      })
      return null;
    }

    // this.getAudienceDetails(audienceId);

    audienceService.getIndexesAudienceByIds(ids, audienceId, 'dooh').then(
      result => {
        const cartWithAudience = cart.map(a => {
          let audienceFound = result.find(r => (r.faceId === a.idKinFace) || (a.parentFrame !== null && r.faceId === a.parentFrame))
          a.index = typeof audienceFound !== 'undefined' ? audienceFound.index : null;
          return a;
        })

        this.setState({
          cart: cartWithAudience,
          audiences: result,
          loadingAudience: false
        })
      },
      error => {
        toast.error('Error: ' + error);
        const cartWithAudienceNull = cart.map(a => {
          a.index = null;
          return a;
        })
        this.setState({
          audiences: [],
          cart: cartWithAudienceNull,
          loadingAudience: false
        })
      }
    )
  }

  loadAudiences(){
    const {audienceFilter} = this.props

    this.setState({
      audienceFilter,
      loadingAudience: audienceFilter !== null
    }, () => audienceFilter !== null && setTimeout(this.getAudienceIndexes, 2000))
  }

  getValuesByField(field, filters, page){
    this.setState({
      loadingValues: true
    })
    doohService.getDistinctByFieldFiltered(field, filters, page).then(
      result => {
        if(result.content.length>0){
          const resultObj = result.content.map(item=>{
            return {
              text: item,
              value: item,
            }
          })

          let values = this.state.values[field]
          values = values.concat(resultObj)

          this.setState((state)=> ({
            values: {
              ...state.values,
              [field]: values
            }
          }))

          if(page < result.totalPages - 1){
            this.getValuesByField(field, filters, page + 1)
          }
          else{
            this.setState({
              loadingValues: false
            })
          }
        }
        else{
          this.setState({
            loadingValues: false
          })
        }
      }
    )
  }

  resetSelected(){
    this.setState({
      selectedRowKeys: []
    })
  }

  addToCart(){
    const {selectedRowKeys} = this.state
    const size = selectedRowKeys.length
    this.props.handleAddToCart(selectedRowKeys)

    this.setState({
      showCart: true,
      selectedRowKeys: [],
    }, ()=> toast.success(size + ' item' + (size!==1 ? 's' : '') + ' added to cart!'))
  }

  resetValues(){
    this.setState({
      values: DEFAULT_VALUES
    })
  }

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleToggleForm() {
    this.setState((state)=>({
      isFormHidden: !state.isFormHidden
    }));
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleTableLayoutChange = e => {
    this.setState({ tableLayout: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({ expandable: enable ? expandable : undefined });
  };

  handleEllipsisChange = enable => {
    this.setState({ ellipsis: enable });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleYScrollChange = enable => {
    this.setState({ yScroll: enable });
  };

  handleXScrollChange = e => {
    this.setState({ xScroll: e.target.value });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handleChangeTable = (pagination, nextFilters, sorter) => {
    let {filters} = this.state

    const isEmptyFilter = !Object.values(nextFilters).some(x => (x !== null && x !== ''));

    Object.keys(nextFilters).map(key => {
      if(nextFilters[key] && nextFilters[key].length > 0){
        filters[key] = nextFilters[key]
      }
      else if(nextFilters[key] === null){
        filters[key] = null;
      }
      //else MALEDETTI CINESI!!!
    })

    const filtersArray = Object.keys(filters).map(function(key) {
      if(filters[key] && filters[key].length > 0){
        return {
          field: key,
          value: filters[key]
        }
      }
    }).filter(item => item !== undefined);

    this.setState({
      filters: !isEmptyFilter ? filters : {},
      filtersArray,
      isEmptyFilter
      // sortedInfo: sorter // TO DO...
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  getColumnTemplate(fieldName, label = null, isBoolean = false, customRender = null, fixed = null, width = null, isSearch = false, commaSeparated = false, sort = true){

    const {readonly} = this.props || false
    const isVisible = this.state.activeColumns.find(col=> col.field===fieldName);
    // sortedInfo = sortedInfo || {};
    return {
      title: !label ? this.strToCapitalize(fieldName) : label,
      dataIndex: fieldName,
      ...(sort && {sorter: (a, b) => sortNames(a[fieldName], b[fieldName])}),
      // sortOrder: sortedInfo.columnKey === fieldName && sortedInfo.order, //TO DO.. inibisce l'onchange del sorting
      filters: (!readonly) && !isSearch && this.state.values[fieldName],
      onFilter: (value, record) => {
        const name = record[fieldName]!==null && (isBoolean ? record[fieldName].toString().toUpperCase() : record[fieldName].toUpperCase())

        if(isSearch){
          if(commaSeparated){
            if(typeof name !== 'string'){
              return false;
            }
            const itemList = name.split(',');
            const orSearchList = value.split(',');

            return itemList.some(item => orSearchList.includes(item));
          }

          return (
            (record[fieldName]!==null) ? 
              (name.toUpperCase().includes(value.toUpperCase())) 
            : (value === "(Empty)") 
          )
        }

        return (
          (record[fieldName]!==null) ? 
            (name === value.toUpperCase()) 
          : (value === "(Empty)") 
        )
      },
      filterSearch: !isSearch,
      filterDropdown:  !isSearch ? null : ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={e => e.stopPropagation()}
        >
          <Input
            // ref={searchInput}
            placeholder={`Search`}
            value={`${selectedKeys[0] || ""}`}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, fieldName)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <Space>
            <Button
              color="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, fieldName)}
              icon={"search"}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={clearFilters}
              color="danger"
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              Cancel
            </Button>
          </Space>
        </div>
      ),
      className: (isVisible && isVisible.visible) ? '' : 'd-none',
      onFilterDropdownVisibleChange: visible => !isSearch && this.handleFilterDropdown(visible, fieldName),
      filterIcon: filtered => this.state.loadingValues ? <LoadingOutlined style={{ color: '#1890ff'}}/> : (!isSearch ? <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }}/> : <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>),
      filteredValue: this.state.filters[fieldName] || null,
      fixed: fixed,
      width: width,
      ellipsis: true,
      render: (value, record) => {
        if(customRender !== null){
          return customRender(value, record) 
        }
        
        if(isBoolean){
          if(value===true){
           return 'ON'
          }else if(value===false){
           return 'OFF'
          }
        } else {
          return value
        }
      }
    }
  };

  handleFilterDropdown(visible, fieldName){
    if(visible){
      const {filters} = this.state
      let filtersCleaned = _.pickBy(filters, _.identity);
      filtersCleaned = _.omit(filtersCleaned, fieldName);
      this.getValuesByField(fieldName, filtersCleaned, 0)
    }
    else{
      setTimeout(this.resetValues, 50);
    }
  }

  resetFilters(){
    this.setState({
      filters: {}
    }, () => this.handleChangeTable({current: 1, pageSize: 10, position: [this.state.top, this.state.bottom]}, {}, {}))
  }

  handleVisibleChangeDropdownColumns = flag => {
    this.setState({ visibleDropdownColumns: flag });
  };

  handleChangeToggleColumn = (ev, field) => {
    const {checked} = ev.target
    let {activeColumns} = this.state
    activeColumns = activeColumns.map(col => {
      if(col.field === field){
        col.visible = checked
      }
      return col
    })
    this.setState({
      activeColumns
    })
  }

  renderHeatmap = (faceIds, record = null) => {
    const titleHeatmap = record === null ? (
      faceIds.length === 1 ? 
        ('Heatmap ID: ' + faceIds)
        : 'Heatmap of ' + faceIds.length + ' frames'
      )
    :
    (record.city + " - " + record.publisher + " - " + record.type + " - " + record.installation)

    return(
      <ButtonAntd
        type="link"
        icon={<DotChartOutlined/>}
        onClick={ () => {
          Modal.info({
            title: titleHeatmap,
            closable: true,
            width: 850,
            content: (
              <HeatMapTableAudience faceIds={faceIds} audienceFilter={this.state.audienceFilter} type='dooh'/>
            ),
            // onOk() {},
          });
        }}
      />
    )
  }

  renderCoverage = (value, count = null) => {
    let color = "danger";

    if(value === 100 || value === null){
      color = "success";
    }
    else if(value >= 10){
      color = "warning";
    }
    else{
      color = "danger";
    }

    return(
      <h4 className="m-0">
        <BadgeTabler
          color={color}
          key={value}
        >
          {count !== null && `${count} -`} {!value ? 100 : Number.isInteger(value) ? value : value.toFixed(1)}%
        </BadgeTabler>
      </h4>
    )
  }

  renderPois = (pois) => {
    const renderPoi = (poi, coverage = null, doohInProximityId = [], isOnPopover = false) => (
      <div className="row">
        <div className="col-auto">
          <span style={{width: isOnPopover ? "auto" : (WIDTH_POIS_COLUMN - 50)}} className="text-truncate d-block">
            <PushpinOutlined/> {poi.brand !== "" ? poi.brand : poi.name}
          </span>
        </div>
        {coverage !== null && (
          <div className="col">
            {this.renderCoverage(coverage, doohInProximityId.length > 0 ? doohInProximityId.length : null)}
          </div>
        )}
      </div>
    )

    return (
      <>
        {pois.length === 0 ? "?" :
          pois.length === 1 ? (
            renderPoi(pois[0].poiDto)
          ) : (
            <Popover content={pois.map(({poiDto, coverage, doohInProximityId}) => renderPoi(poiDto, coverage, doohInProximityId, true))} title="Multiple POIs">
              <strong>{(pois.length + " points")}</strong> <InfoCircleOutlined className="ml-1"/>
            </Popover>
          )
        }
      </>
    )
  }

  renderIndex = (value, record) => {
    if(this.state.loadingAudience){
      return <Spin size="small" className="ml-2"/> 
    }

    let faceIds = []
    
    if(record.childrenIdKinFrame === null){
      if(record.parentFrame === null){
        faceIds = [record.idKinFace]
      }
      else{
        faceIds = [record.parentFrame]
      }
    }
    else{
      faceIds = record.childrenIdKinFrame
    }

    return ((value && !isNaN(value)) || (record.parentIndex && !isNaN(record.parentIndex)) || record.childrenIdKinFrame !== null) ? (
      <>
        {record.childrenIdKinFrame === null ?
          <>
            {record.parentFrame === null && <BadgeTabler color="info" className="mr-1">{value.toFixed(2)}</BadgeTabler>}
            {record.parentFrame !== null && <BadgeTabler color="secondary" className="mr-1">{value.toFixed(2)}</BadgeTabler>}
            {this.renderHeatmap(faceIds, record)}
          </>
          :
          // <AudienceIndexMinMax ids={faceIds} audienceFilter={this.state.audienceFilter} type='dooh' extra={this.renderHeatmap(faceIds)}/>
          <>
            {
              (isNaN(record.minIndex) && isNaN(record.maxIndex)) ? "-"
              :
              <>
                  <BadgeTabler color="danger" className="mr-1">{record.minIndex.toFixed(2)}</BadgeTabler>
                  <br/>
                  <BadgeTabler color="success" className="mr-1">{record.maxIndex.toFixed(2)}</BadgeTabler>
                  {this.renderHeatmap(faceIds)}
              </>
            }
          </>
        }
        
      </>
    )
    : (typeof record.parentIndex !== "undefined" && record.parentIndex !== null ? record.parentIndex : '-')
  }

  onFilterIndex = (indexMin, indexMax) => {
    const {lockFilter} = this.state

    this.setState({
      lockFilterValues: {indexMin, indexMax},
      isLoadingFilter: true
    })

    if(lockFilter) {
      clearTimeout(lockFilter);
    }

    this.setState({
      lockFilter: setTimeout(this.handleFilterIndex, 2000),
      isLoadingFilter: true
    })
  }

  condMinMaxFilter = (item, indexMin, indexMax) => {
    const condSingle = (
      item.index !== null)
      && (
        indexMin === null
        ||
        (item.index >= indexMin)
      )
      && (
        indexMax === null
        ||
        (item.index <= indexMax)
    )

    const condNetwork = (
      (!isNaN(item.minIndex) && !isNaN(item.maxIndex))
      &&(
        (
          indexMin === null
          ||
          (item.minIndex >= indexMin && item.minIndex <= indexMax)
        )
        ||
        (
          indexMax === null
          ||
          (item.maxIndex >= indexMin && item.maxIndex <= indexMax)
        )
      )
    )

    return (condSingle || condNetwork)
  }

  handleFilterIndex = () => {
    const {lockFilterValues, hideExcluded, dataAll} = this.state

    if(!lockFilterValues){
      this.setState({
        isLoadingFilter: false
      })
      return;
    }

    const {indexMin, indexMax} = lockFilterValues

    const data = dataAll.filter(item => {
      const isIndexValidate = (
        (item.index === null && !hideExcluded)
        ||
        ( this.condMinMaxFilter(item, indexMin, indexMax))
      )
      return isIndexValidate
    })

    this.setState({
      indexMin, indexMax, isLoadingFilter: false, data
    })
  }

  handleChangeFilterHideExcluded = (value) => {
    this.setState({
      hideExcluded: value,
      isLoadingFilter: true
    }, () => setTimeout(this.handleFilterIndex, 1500))
  }

  render() {
    const { xScroll, yScroll, isFormHidden, data, cart, selectedRowKeys, loading, loadingAudience, ...state } = this.state;
    const {readonly} = this.props || false
    const isAddressVisible = this.state.activeColumns.find(col=> col.field==='address');

    const scroll = {};
    if (yScroll) {
      scroll.y = 500;
    }
    if (xScroll) {
      scroll.x = '100vw';
    }

    let columns = [
      {
        title: "",
        dataIndex: readonly ? 'modality' : 'childrenCount',
        width: readonly ? 60 : 30,
        fixed: 'left',
        render: (item, record) => item ?
          (readonly
            ? (<span>
                {item.toUpperCase(item)}
              </span>)
            :(
              <>
              </>
            )
          )
        : null
      },
      {
        title: "N. Sites",
        dataIndex: readonly ? 'modality' : 'childrenCount',
        width: readonly ? 100 : 74,
        fixed: 'left',
        render: (item, record) => (
          <div className="badge badge-primary py-1 px-2" key={`badge-${record.id}`} style={{fontSize: ".9em"}}>
            <strong>
              {item || 1}
            </strong>
          </div>
        )
      },
      this.getColumnTemplate('type', 'Environment', false, null, null, 130),
      this.getColumnTemplate('installation', 'Type'),
      this.getColumnTemplate('publisher', false, false, null, false, 180),
      this.getColumnTemplate('region'),
      this.getColumnTemplate('district', false, false, null, false, 100),
      this.getColumnTemplate('city'),
      this.getColumnTemplate(
        'postalCode',
        "Zip code",
        false,
        (value, record) => (
          (record.modality === "NETWORK" && !readonly) ? <em className="text-muted" title={value}>Multiple values</em> : (value ? value : "NA")
        ),
        null,
        null,
        true,
        true,
        false
      ),
      this.getColumnTemplate('audio','Audio', true),
      {
        title: 'Address',
        dataIndex: 'site',
        visible: false,
        ellipsis: true,
        width: 150,
        className: (isAddressVisible && isAddressVisible.visible) ? '' : 'd-none',
        render: (value, record) => (
          (record.modality === "NETWORK" && !readonly) ? <em className="text-muted" title={value}>Multiple values</em> : (value ? value : "NA")
        ),
      },
      this.getColumnTemplate('adsDuration', 'Duration'),
      this.getColumnTemplate('mediaOwner', 'Media Owner'),
      this.getColumnTemplate('dataType', 'Data Type'),
      this.getColumnTemplate('videoType', 'Video Type'),
      this.getColumnTemplate('videoFormat', 'Video Format'),
      this.getColumnTemplate('orientation', 'Orientation'),
      this.getColumnTemplate('screenFormat', 'Screen Format'),
      this.getColumnTemplate('screenType', 'Screen Type'),
      this.getColumnTemplate('cinema', 'Cinema'),
      this.getColumnTemplate('gdo', 'GDO'),
      this.getColumnTemplate('note', 'Note', false, null, null, null, false, false, false),
      this.getColumnTemplate('index', 'Index', false, this.renderIndex, 'right', null, false, false, false )
    ];
    
    if(this.state.isFiltered){
      const firstColumn = columns.shift();
      const secondColumn = columns.shift();
      columns.unshift(this.getColumnTemplate(
        'distance',
        'Distance',
        false,
        (distance) => distance ? <Tag>{distance.value + " m"}</Tag> : <span className="text-muted">Multiple values</span>,
        null,
        null,
        null,
        null,
        false
      ));
      columns.unshift(this.getColumnTemplate(
        'coverage',
        'Proximity',
        false,
        (coverage, items) => {
          const average = items.pois && items.pois.length > 0 ? (items.pois.reduce((acc, item) => acc + item.coverage, 0) / items.pois.length) : 0;
          const count = items.pois && _.uniq(items.pois.map(poi => poi.doohInProximityId).flat()).length;
          return this.renderCoverage(average, count || null);
        },
        null,
        null,
        null,
        null,
        false
      ));
      columns.unshift(this.getColumnTemplate(
        'pois',
        "Poi's",
        false,
        (items) => items && this.renderPois(items),
        null,
        WIDTH_POIS_COLUMN,
        null,
        null,
        false
      ));
      columns.unshift(secondColumn);
      columns.unshift(firstColumn);
    }

    if(readonly){
      columns.shift()
      columns.shift()
      columns.unshift({
        title: <DesktopOutlined />,
        dataIndex: 'numberMonitors',
        width: 100,
        fixed: "left",
        render: (item, record) => (
          <>
            <Badge count={item} overflowCount={1000} style={{backgroundColor: "#cf467f"}} />
            {record.audio ? <AudioOutlined className="float-right mt-1"/> : <AudioMutedOutlined className="float-right mt-1"/>}
          </>
        )
      })
    }

    if(!this.state.audienceFilter){
      columns.pop();
    }

    const tableColumns = columns.map(item => ({ ...item, ellipsis: state.ellipsis }));
    if (xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const rowSelection = {
      selectedRowKeys,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT
      ],
      getCheckboxProps: record => ({
        disabled: cart.indexOf(record.id) !== -1,
        id: record.id,
      }),
      onChange: this.onSelectChange,
    }

    const menu = (
      <div className="bg-white border shadow">
        <List
          size="small"
          // bordered
          dataSource={this.state.activeColumns}
          renderItem={item => <List.Item>
              <Checkbox 
                key={item.field} 
                checked={item.visible} 
                disabled={item.disabled} 
                onChange={(ev) => this.handleChangeToggleColumn(ev, item.field)}
              >
                  {item.label}
              </Checkbox>
            </List.Item>
          }
        />
      </div>
    );

    const renderFilterRangeIndexes = () => {
      const {data, dataAll, isLoadingFilter, indexMin, indexMax} = this.state
      return(
        <React.Fragment>
          <div className="row">
            <div className="col">
            <MapFilterSlider
              onChange={this.onFilterIndex}
              hideExcluded={this.state.hideExcluded}
              setHideExcluded={(value) => this.handleChangeFilterHideExcluded(value)}
              isInsideMap={false}
              defaultMin={_.min(dataAll.map(({index}) => parseFloat(index)).filter(num => !isNaN(num)))}
              defaultMax={_.max(dataAll.map(({index}) => parseFloat(index)).filter(num => !isNaN(num)))}
              value={[indexMin, indexMax]}
              loading={isLoadingFilter}
              count={data.length}
            />
            </div>
          </div>
         
        </React.Fragment>
      )
    }

    const renderColumnsTool = (
      <React.Fragment>
        <Dropdown
          overlay={menu}
          onVisibleChange={this.handleVisibleChangeDropdownColumns}
          visible={this.state.visibleDropdownColumns}
        >
          <ButtonAntd size="small">
            <SettingOutlined /> Show/Hide Columns
          </ButtonAntd>
        </Dropdown>
      </React.Fragment>
    )

    const renderIndexFilterTool = (
      <React.Fragment>
        {
          (readonly !== true && this.state.audienceFilter !== null && (!loading && !loadingAudience)) &&
          <Popover placement="bottom" content={renderFilterRangeIndexes()} trigger="click">
            <ButtonAntd className="ml-2" size="small">
              <FilterOutlined /> Filter by Index <BadgeTabler className="ml-1" color="info">{this.state.audienceFilter.label}</BadgeTabler>
            </ButtonAntd>
          </Popover>
        }
      </React.Fragment>
    )

    const renderFiltered = () => !this.state.isEmptyFilter && (
      <React.Fragment>
        <Icon name="filter" className="mr-3"/>
        {this.state.filtersArray.map(({field, value}) =>
          value.map(value => {
            if(value === "TRUE" || value === "FALSE"){
              value = value === "TRUE" ? "ON" : "OFF";
            }

            switch(field){
              case "audio":
              case "district":
              case "adsDuration":
                return <Tag color="blue">{field.toUpperCase()}: {value}</Tag>;
              default:
                return <Tag color="blue">{value}</Tag>;
            }
          })
        )}
        <Button color="link" size="sm" className="text-danger" onClick={this.resetFilters}>Reset filters</Button>
      </React.Fragment>
    )
    
    const totalScreens = readonly ? cart.length : (
      !data ? "-" : data.reduce((acc, item) => acc + (item.childrenCount ? item.childrenCount : 1), 0)
    );

    const formatNumber = (num) => {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    return (
      <div className={styles}>
      { !readonly &&
        <>
          <div className="row">
            <div className="col">
              <h6 style={{minHeight: 24}}>
                Search results: {formatNumber(totalScreens)} sites
                <span className="ml-3">{this.state.resultMode === "list" && renderColumnsTool}</span>
              </h6>
            </div>
            <div className="col">
              <div className="row">
                <div className="col-12 text-right">
                {renderIndexFilterTool}
                  <Segmented
                    className="ml-2"
                    options={[{
                      label: "LIST",
                      value: "list",
                      icon: <UnorderedListOutlined />
                    },
                    {
                      label: "MAP",
                      value: "map",
                      icon: <i className="fe fe-map mr-1"/>
                    }]}
                    size="small"
                    onChange={(value) => this.setState({resultMode: value})}
                  />
                  
                </div>
              </div>
            </div>
          </div>
          {this.state.resultMode === "list" && 
            <div className="row mb-0">
              <div className="col">
                {renderFiltered()}
              </div>
              <div className="col text-right mb-2">
                {selectedRowKeys.length>0 &&
                <Button.List>
                  <Button className="ml-2 text-danger" size="sm" color="link" onClick={this.resetSelected} disabled={selectedRowKeys.length<=0} loading={loading}>
                  Reset selection
                  </Button>
                  <Button className="ml-2" color="primary" size="sm" onClick={this.addToCart} disabled={selectedRowKeys.length<=0} loading={loading} icon="shopping-cart">
                  Add to Cart <span className="bg-white text-primary px-1 ml-2">{selectedRowKeys.length}</span>
                  </Button>
                </Button.List>
                }
              </div>
            </div>
          }
        </>
      }

      {this.state.resultMode === "list" && (
        <Table
          rowSelection={readonly ? false : rowSelection}
          pagination={{ position: [this.state.top, this.state.bottom] }}
          columns={tableColumns}
          dataSource={state.hasData ? (readonly ? cart : data) : null}
          scroll={columns.length > 8 ? { x: 2800 } : false}
          loading={loading || loadingAudience}
          onChange={this.handleChangeTable}
          size="small"
        />
      )}
      {this.state.resultMode === "map" && (
        <Card.Map className="shadow m-0 position-relative">
          <MapPlannerReact
            data={data}
            type="sightline"
            typeFrame="dooh"
            handleMarkerSelected={(selectedMarker) => this.setState({selectedMarker})}
            polygons={(this.props.polygons && _.isArray(this.props.polygons.features) && this.props.polygons.features.length > 0) ? this.props.polygons.features[0].geometry.coordinates : []}
            distance={this.props.distance} 
            travelType={this.props.travelType} 
            hasSegment={false} 
            audienceFilter={null}
            pois={this.props.pois}
          />
          <DetailMap
            type="dooh"
            marker={this.state.selectedMarker}
            dataMap={this.state.dataAll}
            audienceFilter={this.state.audienceFilterActive}
            onClose={()=>this.setState({selectedMarker: null})}
          />
        </Card.Map>
      )}
      </div>
    );
  }
}

export default DoohPlanCart;
