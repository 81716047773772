// @flow

import * as React from "react";

import {Alert, Button as ButtonAntd, Drawer, InputNumber, Popconfirm, Segmented, Slider, Spin, Tag} from 'antd'
import {PlusOutlined, PushpinOutlined} from '@ant-design/icons';
import {Dimmer} from 'tabler-react';

import _ from "lodash"

import { poiService } from "../../_services";

import PoiManage from "../../Planner/Filters/PoiManage.react";
import {toast} from "react-toastify"

const OPTIONS_PROX_MODES = [{
    label: 'Radius',
    value: 'radius'
  },
  {
    label: 'Travel',
    value: 'travel'
  }
];

const OPTIONS_TRAVEL_MODES = [{
    label: 'Drive',
    value: 'drive'
  },
  {
    label: 'Bicycle',
    value: 'bicycle'
  },
  {
    label: 'Walk',
    value: 'walk'
  }
];

class PoiFilter extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      lat: "",
      lng: "",
      radius: 0,
      POIs: [],
      assets: [],
      size: 0,
      groupName: "",
      defaultRadius: 0,
      defaultTravelRadius: 10,
      proximityMode: "radius",
      travelType: "time",
      travelMode: "drive",
      travelTraffic: "approximated",
      travelRouteType: "balanced",
      polygons: [],
      isTooMuch: false,
      jsonLoading: false,
      xlsLoading: false,
      xlsError: null,
      isOpenModal: false,
      tabModal: "Open",
      groupList: [],
      issetFile: false,
      errorMessage: "",
      locationSearch: null,
      loadingPoi: true
    };

    this.handleChangeRadius = this.handleChangeRadius.bind(this);
    this.toggleModalPOI = this.toggleModalPOI.bind(this);
    this.emptyPOIs = this.emptyPOIs.bind(this);
    this.removeChild = this.removeChild.bind(this);
    this.addPOIByGroup = this.addPOIByGroup.bind(this);
    this.onChangeValueByfield = this.onChangeValueByfield.bind(this);
  }

  componentDidMount(){
    const {
      POIs,
      radius,
      proximityMode = "radius",
      travelType,
      travelMode,
      travelTraffic,
      travelRouteType
    } = this.props

    this.setState((state)=>({
      POIs,
      loadingPoi: false,
      defaultRadius: state.defaultRadius === 0 ? radius : state.defaultRadius,
      radius,
      proximityMode,
      travelType,
      travelMode,
      travelTraffic,
      travelRouteType,
    }))
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.proximityMode !== this.props.proximityMode){
      const proximityMode = nextProps.proximityMode
        this.setState({
          proximityMode
        })
        this.props.handleChangeTravelProp("proximityMode", proximityMode)
    }
    if(nextProps.travelType !== this.props.travelType){
        const travelType = nextProps.travelType
        this.setState({
          travelType
        })
        this.props.handleChangeTravelProp("travelType", travelType)
    }
    if(nextProps.travelMode !== this.props.travelMode){
        const travelMode = nextProps.travelMode
        this.setState({
          travelMode
        })
        this.props.handleChangeTravelProp("travelMode", travelMode)
    }
    if(nextProps.travelTraffic !== this.props.travelTraffic){
        const travelTraffic = nextProps.travelTraffic
        this.setState({
          travelTraffic
        })
        this.props.handleChangeTravelProp("travelTraffic", travelTraffic)
    }
    if(nextProps.travelRouteType !== this.props.travelRouteType){
        const travelRouteType = nextProps.travelRouteType
        this.setState({
          travelRouteType
        })
        this.props.handleChangeTravelProp("travelRouteType", travelRouteType)
    }

    if(nextProps.polygons !== this.props.polygons){
        const polygons = nextProps.polygons
        this.setState({
          polygons
        })
        this.props.handleChangePolygons(polygons)
    }
    if(!_.isEqual(nextProps.POIs, this.props.POIs)){
        const POIs = nextProps.POIs
        this.setState({
          POIs,
          loadingPoi: false
        })
        this.props.handleChangePOIs(POIs)
        this.props.handleStateCount(POIs.length)
    }
    if(nextProps.radius !== this.props.radius){
      const radius = nextProps.radius
      this.setState((state)=>({
        defaultRadius: (state.defaultRadius === 0 || nextProps.radius === 0) ? radius : state.defaultRadius,
        radius
      }))
      this.props.handleChangePOIRadius(radius)
    }
  }

  checkValidationForm(){
    const {POIs, radius} = this.state;
    let condLatLng = POIs.length;
    let condRadius = radius>=1 && radius<=10000;

    return condLatLng && condRadius;
  }

  handleChangeLat(ev){
    let lat = ev.target.value
    this.setState({ lat });
  }

  handleChangeLng(ev){
    let lng = ev.target.value
    this.setState({ lng });
  }

  handleChangeRadius(radius){
    this.setState({ radius });
    this.props.handleChangePOIRadius(radius)
    return radius
  }

  handleChangePolygons(polygons){
    this.setState({ polygons });
    this.props.handleChangePolygons(polygons)
    return polygons
  }

  emptyPOIs(){
    this.props.handleChangePOIs([])
    this.setState({
      POIs: [],
    })
  }

  removeChild(el){
    this.setState({
      loadingPoi: true
    })
    setTimeout(() => {
      const POIs = this.state.POIs.filter((item) => item.id !== el);

      this.setState({
        POIs,
        loadingPoi: false
      }, () => this.props.handleChangePOIs(this.state.POIs));
    }, 1200);
  }

  toggleModalPOI(tabModal) {
    this.setState(prevState => ({
      isOpenModal: !prevState.isOpenModal,
      tabModal
    }));
  }

  callbackCreatePOIs(){
    let {POIs} = this.state
    POIs = this.removeDuplicate(POIs)
    this.props.handleStateCount(POIs.length)
    this.props.handleChangePOIs(POIs)
  }

  removeDuplicate(elements){
    const elementsNoDuplicates = _.uniqBy(elements, 'id');
    const hasDuplicates =  elements.length !== elementsNoDuplicates.length
    if(hasDuplicates){
      toast.warning('Removed POIs duplicate')
    }
    return elementsNoDuplicates
  }

  addPOIByGroup(id){
    if(id){
        poiService.readPOIsByGroup(id).then(
            result => {
              toast.info('POIs added to plan!');
              this.setState(prevState => ({
                  isOpenModal: false,
                  POIs: [...prevState.POIs, ...result.pois]
              }), ()=> this.callbackCreatePOIs())
            }
        )
    }
  }

  onChangeValueByfield(field, value){
    this.setState({
      [field]: value
    })

    switch (field) {
      case "radius":
      case "proximityMode":
      case "travelType":
      case "travelMode":
      case "travelTraffic":
      case "travelRouteType":
          this.props.handleChangeTravelProp(field, value);
        break;
        
      default:
        break;
    }
  }

  render(){
      const hasMoreThanOnePOITravel = (this.state.proximityMode === "travel" && this.state.POIs.length > 1);

      const poisSelected = (
        <React.Fragment>
            <strong className={hasMoreThanOnePOITravel && "text-danger"}>

              {this.state.POIs.length===0 && <em>No POIs chosen</em>}
              <Dimmer active={this.state.loadingPoi} loader={this.state.loadingPoi}>
                {
                  this.state.POIs.map(({id, name, brand}) => (
                    <Tag
                      closable
                      key={id}
                      color="blue"
                      size="large"
                      icon={<PushpinOutlined />}
                      onClose={() => !this.state.loadingPoi && this.removeChild(id)}
                      title={brand + " - " + name} 
                    >
                      <span className="text-truncate" style={{maxWidth: 120}}>{brand !== "" ? brand : name}</span>
                    </Tag>
                  ))
                }
                <Popconfirm
                  placement="top"
                  title="Do you want to reset this proximity search?"
                  onConfirm={this.emptyPOIs}
                  disabled={this.state.POIs.length === 0}
                  okText="Yes"
                  cancelText="No"
                  >
                    <ButtonAntd danger disabled={this.state.POIs.length === 0} size="small" type="link" style={{verticalAlign: "top"}}>
                      Reset search
                    </ButtonAntd>
                </Popconfirm>   
              </Dimmer>
            </strong>
        </React.Fragment>
      )

      const modalManagePOI = (
        <React.Fragment>
          <Drawer
            title="POI Manager"
            placement="right"
            closable={true}
            onClose={()=>this.toggleModalPOI('New')}
            visible={this.state.isOpenModal}
            width={"100vw"}
            // getContainer={false}
          >
            <PoiManage tab={this.state.tabModal} onSelect={this.addPOIByGroup} resourceType="sightline" />
          </Drawer>
        </React.Fragment>
      )

      return(
        <div className="row">
          <div className="col pb-4 pt-4">
            {modalManagePOI}
            <div className="row">
              <div className="col-4">
                <div className="row">
                  <div className="col-auto pt-2">
                    <h5>Proximity (optional)</h5>
                  </div>
                  <div className="col-auto">
                    <ButtonAntd onClick={()=>this.toggleModalPOI('New')} icon={<PlusOutlined/>}>New Poi's</ButtonAntd>
                  </div>
                  {/* <div className="col-auto">
                    <ButtonAntd onClick={()=>this.toggleModalPOI('Open')} icon={<PushpinOutlined />}>Add Pois</ButtonAntd>
                  </div> */}
                </div>
              </div>
              <div className="col-4 text-right">
                {
                  hasMoreThanOnePOITravel &&
                  <>
                    <Alert className="py-1 text-left" showIcon message="Proximity Travel Mode with multiple POIs is not available yet!" type="error" />
                  </>
                }
              </div>
              <div className="col-4 text-right">
                
                <Segmented
                  className="ml-2 mr-4"
                  options={OPTIONS_PROX_MODES}
                  value={this.state.proximityMode}
                  defaultValue={this.state.proximityMode}
                  onChange={value => {
                    this.onChangeValueByfield("radius", value === 'radius' ? 50 : 10)
                    this.onChangeValueByfield("proximityMode", value)
                  }}
                />
                {React.cloneElement(this.props.children, {
                  disabled: hasMoreThanOnePOITravel || (this.state.radius <= 0 || this.state.POIs.length <= 0)
                })}
              </div>
            </div>
            
            <div className="row mt-3">
              <div className="col-6">
                {poisSelected}
              </div>

              <div className="col-3 text-right">
              {
                  this.state.proximityMode === "travel" &&
                  <>
                  <strong className="ml-4">Travel mode</strong>
                    <Segmented
                      className="ml-2"
                      options={OPTIONS_TRAVEL_MODES}
                      value={this.state.travelMode}
                      onChange={type => this.onChangeValueByfield("travelMode", type)}
                    />
                  </>
                }
              </div>

              <div className="col-3 text-right">
                <div className="row">
                  <div className="col-9">
                    <strong>{this.state.proximityMode === "travel" ? "Minutes" : "Meters"}</strong>
                    <Slider
                      min={this.state.proximityMode === "travel" ? 1 : 50}
                      max={this.state.proximityMode === "travel" ? 60 : 10000}
                      step={this.state.proximityMode === "travel" ? 1 : 50}
                      onChange={value => this.handleChangeRadius(value)}
                      defaultValue={this.state.defaultRadius}
                      value={this.state.radius}
                      style={{marginTop: 0, marginRight: "-1px"}}
                    />
                  </div>
                  <div className="col-3">
                    <InputNumber
                      min={this.state.proximityMode === "travel" ? 1 : 50}
                      max={this.state.proximityMode === "travel" ? 60 : 10000}
                      step={this.state.proximityMode === "travel" ? 1 : 50}
                      defaultValue={this.state.defaultRadius}
                      value={this.state.radius}
                      onChange={value => this.handleChangeRadius(value)}
                      style={{maxWidth: 60}}
                    />
                  </div>
                </div>
              </div>
            </div>
            
              
              {/* HIDDEN TYPE
              {
                this.state.proximityMode === "travel" &&
                <Grid.Col sm="3" className="align-self-center">
                  <strong>Choose travel type</strong>
                  <ButtonGroup className="ml-4">
                    <Button color={this.state.travelType === "time" ? "primary" : "secondary"} className="text-center" onClick={()=>this.onChangeValueByfield("travelType", "time")}>Time</Button>
                    <Button color={this.state.travelType === "distance" ? "primary" : "secondary"} className="text-center" onClick={()=>this.onChangeValueByfield("travelType", "distance")}>Distance</Button>
                  </ButtonGroup>
                </Grid.Col>
              } */}


              
              {/* HIDDEN ROUTE
              {
                this.state.proximityMode === "travel" &&
                <Grid.Col sm="3" className="align-self-center">
                  <strong>Travel Route</strong>
                  <ButtonGroup className="ml-2">
                    <Button color={this.state.travelRouteType === "balanced" ? "primary" : "secondary"} className="text-center" onClick={()=>this.onChangeValueByfield("travelRouteType", "balanced")}>Balanced</Button>
                    <Button color={this.state.travelRouteType === "short" ? "primary" : "secondary"} className="text-center" onClick={()=>this.onChangeValueByfield("travelRouteType", "short")}>Short</Button>
                    <Button color={this.state.travelRouteType === "less_maneuvers" ? "primary" : "secondary"} className="text-center" onClick={()=>this.onChangeValueByfield("travelRouteType", "less_maneuvers")}>Simple</Button>
                  </ButtonGroup>
                </Grid.Col>
              } */}

              {/* HIDDEN TRAFFIC
              {
                this.state.proximityMode === "travel" &&
                <Grid.Col sm="1" className="align-self-center">
                  <Checkbox 
                    // key={item.field} 
                    // checked={item.visible} 
                    // disabled={item.disabled} 
                    onChange={(ev) => this.onChangeValueByfield("travelTraffic", ev.target.checked ? "approximated" : "free_flow")}
                  >
                    Traffic
                  </Checkbox>
                </Grid.Col>
              } */}

            
            {/* {
              this.state.proximityMode === "travel" &&
              <Grid.Row>
                <Grid.Col className="text-right mb-2">
                  <Button onClick={()=>this.getIsoline()}>Get Polygons</Button>
                </Grid.Col>
              </Grid.Row>
            } */}

          </div>
        </div>
      )
  }
}

export default PoiFilter;
