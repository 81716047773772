// @flow

import React, {useEffect, useState} from "react";
import {Form, Button, Tree, Transfer, message} from 'antd';
import {audienceService} from '../../_services';

const formConfig = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
  layout: "vertical"
}

const TreeTransfer = ({ dataSource, targetKeys, ...restProps }) => {
  const isChecked = (selectedKeys, eventKey) => selectedKeys.includes(eventKey);
  const getLeafNodes = nodes => {
    let leafNodes = [];
    nodes.forEach(node => {
      if (node.children) {
        leafNodes = leafNodes.concat(getLeafNodes(node.children));
      } else {
        leafNodes.push(node);
      }
    });
    return leafNodes;
  };

  const leafNodes = getLeafNodes(dataSource);

  const renderTree = treeNodes =>
    treeNodes.map(({ children, ...props }) => (
      <Tree.TreeNode
        {...props}
        key={props.key}
        selectable={!(children && children.length)}
        checkable={!(children && children.length)}
        isLeaf={!(children && children.length)}
        className={(children && children.length) && "text-dark"}
      >
        {children ? renderTree(children) : null}
      </Tree.TreeNode>
  ));

  return (
    <Transfer
      {...restProps}
      targetKeys={targetKeys}
      dataSource={leafNodes}
      rowKey={(record) => record.id}
      className="tree-transfer"
      render={(item) => item.label}
      showSelectAll={false}
    >
      {({ direction, onItemSelect, selectedKeys }) => {
        if (direction === 'left') {
          const checkedKeys = [...selectedKeys, ...targetKeys];
            return (
              <Tree
                blockNode
                checkStrictly
                checkable
                defaultExpandAll
                rowKey="id"
                checkedKeys={checkedKeys}
                autoExpandParent
                // treeData={dataSource.map(item => ({
                //   ...item,
                //   checkable: !item.children || item.children.length === 0
                // }))}
                fieldNames={{ title: 'label', key: 'id', children: 'children' }}
                onCheck={(_, { node: { key } }) => {
                  onItemSelect(key, !isChecked(checkedKeys, key));
                }}
                onSelect={(_, { node: { key } }) => {
                  onItemSelect(key, !isChecked(checkedKeys, key));
                }}
              >
                {renderTree(dataSource)}
              </Tree>
            );
        }
      }}
    </Transfer>
  );
};

function AudienceFilter(props) {
  const {onChange} = props
  const [categorySelected, setCategorySelected] = useState(null)

  const [optionsDemographic, setOptionsDemographic] = useState([])
  const [form] = Form.useForm();

  useEffect( () => {
    getAllAudienceType()
  }, [])

  useEffect( () => {
    if(props.value === null){
      onReset();
    }
    else{
      const {id} = props.value;
      getAllAudienceType(id);
    }
  }, [props.value])

  const getAllAudienceType = (selected = null) => {
    audienceService.getAllAudienceType().then(
      result => {
        setOptionsDemographic(result)
        if(!selected)
          return;

        setCategorySelected(selected)
      },
      error => {
        message.error("Error: " + error)
      }
    )
  } 

  const onReset = () => {
    setCategorySelected(null)
    form.resetFields();
    onChange(null)
  }
  
  const findNodesByKey = (nodes, keys) => {
    let foundNodes = [];
    nodes.forEach(node => {
      if (keys.includes(node.id)) {
        foundNodes.push(node);
      }
      if (node.children) {
        foundNodes = foundNodes.concat(findNodesByKey(node.children, keys));
      }
    });
    return foundNodes;
  };

  const onChangeDropdown = (targetKeys, direction) => {
    if(targetKeys === null){
      setCategorySelected(null);
      onChange(null);
      return;
    }
    const newSelectedItems = findNodesByKey(optionsDemographic, targetKeys);
    setCategorySelected(targetKeys);
    onChange(newSelectedItems.length > 0 ? newSelectedItems[0] : null)
  }

  const findCategory = (options, value) => {
    const category = options.find(opt => {
      if(opt.value === value){
        return true;
      }

      if(opt.children){
        return findCategory(opt.children, value);
      }
      return null;
    })

    return category;
  }

  return(
    <Form className="p-3" form={form} {...formConfig}>
      <div className="row">
        <div className="col">
          {
            <TreeTransfer
              dataSource={optionsDemographic}
              targetKeys={categorySelected ? [categorySelected] : []}
              // onChange={(values, items) => setCategorySelected(values.length > 0 ? values[0] : null)}
              onChange={(values, items) => onChangeDropdown(values.length > 0 ? values[0] : null, items)}
              // showSearch
            />
          }
        </div>
      </div>
      <div className="row">
        <div className="col text-right">
          <Button type="link" danger onClick={onReset} disabled={!categorySelected}>Reset</Button>
        </div>
      </div>
    </Form>
  )

}

export default AudienceFilter;
