import React, {useState} from 'react'
import {Button as ButtonAntd, Modal, Table} from 'antd'
import {InfoCircleOutlined} from '@ant-design/icons'
import dataSource from './Dooh.Plan.InfoCPM.mock.json'

const columns = [
  {
    title: 'Environment',
    dataIndex: 'Ambiente',
    key: 'Ambiente',
  },
  {
    title: 'MediaOwner',
    dataIndex: 'Editore',
    key: 'Editore',
  },
  {
    title: 'Publisher',
    dataIndex: 'Concessionario',
    key: 'Concessionario',
  },
  {
    title: 'Type',
    dataIndex: 'Type',
    key: 'Type',
  },
  {
    title: 'Address',
    dataIndex: 'Location',
    key: 'Location',
  },
  {
    title: 'CPM Selling NMS',
    dataIndex: 'CPMSelling',
    key: 'CPMSelling',
    render: value => <strong>{value && value.toString().replace('.',',') + '€'}</strong>
  },
  {
    title: 'CPM Buying NMS',
    dataIndex: 'CPMBuying',
    key: 'CPMBuying',
    render: value => value && value.toString().replace('.',',') + '€'
  },
  {
    title: 'CPM PBU',
    dataIndex: 'CPMPBU',
    key: 'CPMPBU',
    render: value => value && value.toString().replace('.',',') + '€'
  },
];

function onClickInfoCPM(){
  Modal.info({
    title: 'Ratecard CPM',
    content: (
      <Table dataSource={dataSource} columns={columns} size="small" bordered={true} style={{marginTop: "2em"}} />
    ),
    width: 1150,
    // bodyStyle: {margin: 0}, //aggiornare Antd alla 4.8.0
    onOk() {},
  });
}

function DoohPlanInfoCPM({
  buttonClassName = "ml-2",
  buttonSize = "middle",
  buttonType = ""
}){
  return (
    <ButtonAntd
      onClick={onClickInfoCPM}
      className={buttonClassName}
      size={buttonSize}
      type={buttonType}
    >
      <InfoCircleOutlined /> Ratecard CPM
    </ButtonAntd>
  )
}

export default DoohPlanInfoCPM;
