import React, {createContext, useContext, useEffect, useState} from 'react';
import {
    Grid,
    Card,
    Table,
  } from "tabler-react";
import {Drawer, Pagination, Tabs, Tag, Tooltip} from 'antd';
import StreetView from "./StreetView.react";
import Carousel from "../Carousel";

import HeatMapTableAudience from '../../Planner/Planner.HeatMapTableAudience.react'
import {assetService} from '../../_services';
import { InfoCircleOutlined } from '@ant-design/icons';

function DetailMap (props){
    const {type = 'ooh', audienceFilter, dataMap, onClose} = props;
    const [similar, setSimilar] = useState(null)
    const [similars, setSimilars] = useState([])
    const [selectedSimilar, setSelectedSimilar] = useState(1)
    // const parentMarker = props.marker // TODO: prendere il parentFrame, map idKinFrame dei similars

    const marker = (similar !== null) ? {...props.marker, ...similar} : props.marker

    let idKin = null

    if(marker){
        if(type === 'ooh'){
            if(marker.parent){
                idKin = marker.parent
            }
            else{
                idKin = marker.idKinFace
            }
        }
        else if(type === 'dooh') {
            idKin = marker.idKinFrame
        }
    }

    useEffect(() => {
        if(props.marker){
            const {location} = props.marker
            if(location){
                getLocationSimilar(location); //TODO: fix backend for guest
            }
            else{
                setSimilars([])
            }
        }
    }, [props.marker])

    const getLocationSimilar = (location) => {
        assetService.getSimilarAssetsByLocationAndType(type, location).then(
            result => {
                let selected = 0;
                
                if(type === 'ooh'){
                    selected = result.findIndex(sim => sim.idKinFrame === marker.idKinFrame);
                    result = result.filter(sim => dataMap.find(l => l.idKinFrame === sim.idKinFrame))
                }

                if(type === 'dooh'){
                    setSimilar(result[selected])
                }
                setSelectedSimilar(selected + 1)
                setSimilars(result)
            },
            error => {
                setSimilars([])
                setSelectedSimilar(null)
                console.error("Error: "+ error)
            }
        )
        // }
    }

    const onChangeSimilar = (page) => {
        setSelectedSimilar(page)
        const similarObj = similars[page - 1]
        setSimilar(similarObj)
    }

    const renderRowInfo = (label, value) => {
        return (
            <Table.Row>
                <Table.Col>{label}</Table.Col>
                <Table.Col>{value}</Table.Col>
            </Table.Row>
        )
    }

    const onCloseDrawer = () => {
        setSimilar(null)
        onClose()
    }

    const GallerySection = () => (
        type === 'dooh' &&
        (   
            (marker.imageUrl) ? 
                <div className='rounded'>
                    <Carousel images={[marker.imageUrl]} />
                </div>
            : 
            <div className='bg-light p-6 mx-2 rounded'>
                <h3 className='p-6 m-6 text-center'>No Photo</h3>
            </div>
        )
    )

    const StreetViewSection = () => (
        type === 'ooh' &&
        <>
            <div className="text-center">
                <Card.Map className="border p-0 card-streetview">
                    <StreetView lat={marker.location.y} lng={marker.location.x} />
                </Card.Map>
            </div>
        </>
    )

    const InfoSection = () => (
        <Table striped={true} responsive className="table-border-top-0 table-firstcol-header table-md">
            <Table.Body>
                
                {type === 'ooh' && renderRowInfo('Media Owner', marker.mediaOwner)}
                {type === 'ooh' && renderRowInfo('Type', marker.dashboardType)}
                {type === 'dooh' && renderRowInfo('Environment', marker.type)} 
                {type === 'dooh' && renderRowInfo('Publisher', marker.publisher)}
                {type === 'dooh' && renderRowInfo('Type', marker.installation)}
                <Table.Row>
                    <Table.Col>City - Region</Table.Col>
                    <Table.Col>{marker.city} - {marker.region}</Table.Col>
                </Table.Row>
                {type === 'ooh' && renderRowInfo('Address', (marker.address ? marker.address : "-"))}
                {type === 'dooh' && renderRowInfo('Address', (marker.site ? marker.site : "-"))}
                {type === 'dooh' && renderRowInfo('N.Screen', marker.numberMonitors)}
                {type === 'dooh' && renderRowInfo('Orientation', marker.orientation)}
                {type === 'dooh' && renderRowInfo('Video Type', marker.videoType)}
                {type === 'ooh' && renderRowInfo('Segment', marker.segment)}
                {type === 'ooh' && renderRowInfo('Format', marker.format)}
                {type === 'ooh' && renderRowInfo('Cimasa', marker.cimasa)}
                {type === 'ooh' && renderRowInfo('Note', marker.notes)}
                {type === 'dooh' && renderRowInfo('Audio', (marker.audio ? "ON" : "OFF"))}
                {type === 'dooh' && renderRowInfo('Ads Duration', marker.adsDuration)}
                {type === 'dooh' && renderRowInfo('Pixel', 
                    marker.width ? (
                            `${marker.width}${marker.height ? ('x' + marker.height) : ''}`
                        )
                        : "-"
                    )}
            </Table.Body>
        </Table>
    )

    const DayPartsSection = () => (
        <div className="row">
            <div className="col text-center w-100 pl-5" style={{height: 290}}>
                <HeatMapTableAudience
                    partial="totalUserSeen"
                    faceIds={similars.length === 0 ? [idKin] : similars.map(s => s.idKinFrame || s.idKinFace)}
                    audienceFilter={audienceFilter}
                    height={330}
                    type={type}
                />
                <HeatMapTableAudience
                    partial="targetUserSeen"
                    faceIds={similars.length === 0 ? [idKin] : similars.map(s => s.idKinFrame || s.idKinFace)}
                    audienceFilter={audienceFilter}
                    height={330}
                    type={type}
                />
            </div>
        </div>
    )

    return (
        <Drawer
            title={marker && (marker.mediaOwner + "_" + marker.type + "_" + marker.city)}
            bodyStyle={{padding: 14}}
            placement="right"
            closable={false}
            width={560}
            onClose={onCloseDrawer}
            open={marker!==null}
            footer={
                <div className='row'>
                    <div className='col text-right'>
                        <Pagination
                            simple
                            size="small"
                            current={selectedSimilar}
                            total={similars.length}
                            pageSize={1}
                            onChange={(page) => onChangeSimilar(page)}
                        />
                    </div>
                </div>
            }
            // key={placement}
        >
            {marker !== null &&
                <Grid.Row>
                    <Grid.Col md={12} className="card-counters pr-sm-0 pl-sm-0 pt-0">
                        {GallerySection()}
                        {StreetViewSection()}
                    </Grid.Col>
                    <Grid.Col md={12} className="text-left pt-2">
                        <Tabs defaultActiveKey="1" className='mx-3'
                            tabBarExtraContent={(
                                audienceFilter && 
                                    <>
                                        <strong className='text-muted'>
                                            Index 
                                            <Tooltip title="Info index" className='ml-1 mr-2' placement="bottom">
                                                <InfoCircleOutlined />
                                            </Tooltip>
                                            <Tag color="success" style={{"font-size": "1em"}}>
                                                {marker.index !== null ? marker.index : "-"}
                                            </Tag>
                                        </strong>
                                    </>
                            )}
                        >
                            <Tabs.TabPane tab="Info" key="1">
                                {InfoSection()}
                            </Tabs.TabPane>
                            {audienceFilter && 
                                <Tabs.TabPane tab="Audience" key="2">
                                    {DayPartsSection()}
                                </Tabs.TabPane>
                            }
                        </Tabs>
                    </Grid.Col>
                
                </Grid.Row>
            
                    
            }
        </Drawer>
    )
}

export default DetailMap;