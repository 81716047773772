// @flow

import * as React from "react";

import {
  Form,
  Button} from "tabler-react";

import { poiService } from "../../_services";
import {toast} from "react-toastify"

class PoiManageImportXlsm extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            buttonUploadActive: true,
            errorMessage: "",
            issetFile: false,
            groupName: ""
        }

        this.onClickImportPOI = this.onClickImportPOI.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
    }

    onClickImportPOI(e){
        e.preventDefault() // Stop form submit
        const groupPOIName = this.state.groupName;

        this.setState({
            buttonUploadActive: false
        })

        let formData = new FormData()
        const file = this.state.issetFile[0]

        formData.append("file", file, file.name);

        poiService.importPOIByXls(formData, groupPOIName, this.props.resourceType).then(
            result => {
              let pois = result.pois;
              if(pois){
                toast.success('POIs imported successful! ' + pois.length + ' saved.')

                const {idPoiGroup} = pois[0]
                this.props.onSave(idPoiGroup, groupPOIName)
              }
              else{
                  toast.error('File is empty, nothing to import.')
                  this.setState({
                      errorMessage: "Empty file"
                  })
              }
            },
            error => {
              toast.error('File not valid: '+error.toString())
              console.error(error);
            }
        );
    }

    handleChangeFile(ev){
        this.setState({
            issetFile: ev.target.files
        })
    }

    handleChangeInput(ev){
      let groupName = ev.target.value
      this.setState({ groupName });
    }


    render(){
        return(
            <div className="p-3">
                <div className="row">
                    <div className="col-6 offset-3 mb-1">
                        Select template <code>xlsm</code> edited to import POIs
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 offset-3">
                      <Form.Group onSubmit={this.onClickImportPOI}>
                        <Form.Input className="mb-3" placeholder="Insert Group POI name" required onChange={this.handleChangeInput} />
                        <Form.FileInput accept=".xlsm" disabled={this.state.groupName.trim() === ""}
                          onChange={this.handleChangeFile}
                        />
                      </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 offset-3">
                        <Button color="primary" onClick={this.onClickImportPOI} disabled={this.state.groupName.trim() === "" || !this.state.issetFile}>Import Xlsm</Button>
                    </div>
                </div>
            </div>
        )
    }

}

export default PoiManageImportXlsm
