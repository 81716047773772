import React, { useEffect, useState } from "react";
import {Tag, Icon, Badge, Dimmer, Card} from 'tabler-react'
import {Table} from 'antd'
import MapPlanner from "../components/Map/MapPlanner.react";
import DetailMap from '../components/Map/DetailMap.Planner.react'

function PlannerResultList (props) {
	const {id} = props;
	const [selectedMarker, setSelectedMarker] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [audienceFilterObj, setAudienceFilterObj] = useState(null)
	const [assets, setAssets] = useState([])

	useEffect(()=>{
		setIsLoading(true)
		if(props.assets){
			setAssets(props.assets)
			const hasIndex = props.assets.find(item => typeof item.index !== 'undefined')
			if(hasIndex) {
				setTimeout(() => setIsLoading(false), 1000)
			}
		}
	},[props.assets])

	useEffect(() => {
		setAudienceFilterObj(props.audienceFilter)
	},[props.audienceFilter])

	const handleMarkerSelected = (nextSelected) => {
		if(nextSelected !== selectedMarker){
		  setSelectedMarker(nextSelected)
		}
	}

	let columns =
	(assets[0] && assets[0].poi) ?
		[
			{
				title: <div className="text-muted">POI Location</div>,
				dataIndex: '',
				key: 'POIlocation',
				align: 'left',
				render: (value) => value.poi && <><div><b>POI Name: </b>{value.poi.name}</div> <div><b>POI Type: </b>{value.poi.type}</div> <div><b>POI Address: </b>{value.poi.address}</div> </>
			},
			{
				title: <div className="text-muted">Media Owner</div>,
				dataIndex: '',
				align: 'left',
				key: 'mediaOwner',
				render: (value) => <Tag>{value.mediaOwner}</Tag>
			},
			{
				title: <div className="text-muted">Type</div>,
				dataIndex: '',
				align: 'left',
				key: 'type',
				render: (value) => <><div className="my-2"><Tag>{value.techType}</Tag></div> <div><Tag>{value.format}</Tag></div></>
			},
			{
				title: <div className="text-muted">Location</div>,
				dataIndex: '',
				key: 'location',
				align: 'left',
				render: (value) => <><div><b>City: </b>{value.city}</div> <div><b>Address: </b>{value.address}</div> <div><b>Lat: </b>{value.location.y} - <b>Long: </b> {value.location.x}</div> </>
			},
			{
				title: <div className="text-muted">Street View</div>,
				dataIndex: '',
				key: 'streetView',
				align: 'center',
				render: (value) => <Icon name="map" className="fa-lg" link onClick={()=> window.open("https://www.google.com/maps/@?api=1&map_action=pano&viewpoint="+value.location.y+","+value.location.x, "_blank")}/>
			},
			{
				title: <div className="text-muted">POI Distance</div>,
				dataIndex: '',
				key: 'distance',
				align: 'center',
				sorter: (a,b)=>(a.index - b.index),
				// defaultSortOrder: 'ascend',
				render: (value) => value.distance && <Tag>{value.distance.value + " m"}</Tag>
			}
		]
	:
		[
			{
				title: <div className="text-muted">Location</div>,
				dataIndex: '',
				key: 'location',
				align: 'left',
				render: (value) => <><div><b>City: </b>{value.city}</div> <div><b>Address: </b>{value.address}</div> <div><b>Lat: </b>{value.location.y} - <b>Long: </b> {value.location.x}</div> </>
			},
			{
				title: <div className="text-muted">Media Owner</div>,
				dataIndex: '',
				align: 'left',
				key: 'mediaOwner',
				render: (value) => <Tag>{value.mediaOwner}</Tag>
			},
			{
				title: <div className="text-muted">Type</div>,
				dataIndex: '',
				align: 'left',
				key: 'type',
				render: (value) => <><div className="my-2"><Tag>{value.techType}</Tag></div> <div><Tag>{value.format}</Tag></div></>
			},
			{
				title: <div className="text-muted">Street View</div>,
				dataIndex: '',
				key: 'streetView',
				align: 'center',
				render: (value) => <Icon name="map" className="fa-lg" link onClick={()=> window.open("https://www.google.com/maps/@?api=1&map_action=pano&viewpoint="+value.location.y+","+value.location.x, "_blank")}/>
			},
			
	]
	
	if(audienceFilterObj){
		columns.push({
			title: <div className="text-muted">Index</div>,
			dataIndex: '',
			key: 'index',
			align: 'center',
			sorter: (a,b)=>(a.index - b.index),
			defaultSortOrder: 'descend',
			render: (value) => (
				<Dimmer active={isLoading} loader={isLoading}>
					{ value.index ? <Badge color={value.isIndexParent ? "secondary" : "info"} className="mr-1">{value.index.toFixed(2)}</Badge> : '-'}
				</Dimmer> 
			)
		})
	}

	return(
		props.tabActive === 0 ?
		(	
			(
				(assets && assets.length > 0) ?
					!isLoading ? //Workaround table rendering antd
						<Table
							dataSource={assets}
							columns={columns}
							pagination={{defaultPageSize: 20}}
						/>
					:
					<Dimmer active={isLoading} loader={isLoading}>
						<div className='p-6'>
							<h3 className='text-center p-6'>Loading...</h3>
						</div>
					</Dimmer>
				:
				<div className="row">
					<div className="col-12">
						<h4 className="text-center m-0 p-6 bg-light">No result found</h4>
					</div>
				</div>
			)
		)
		:
		(
			<>
				<Card.Map className="shadow">
					<MapPlanner 
						id={id}
						type="planner"
						data={assets} 
						countPOIs={props.countPOIs} 
						distance={props.distance} 
						travelType={props.travelType} 
						audienceFilter={audienceFilterObj}
						polygons={(props.polygons && props.polygons.features) ? props.polygons.features[0].geometry.coordinates : []}
						enableSearch 
						selectedMarker={selectedMarker}
						handleMarkerSelected={handleMarkerSelected} 
					/>
				</Card.Map>
				<DetailMap type="ooh" marker={selectedMarker} dataMap={assets} onClose={()=>setSelectedMarker(null)} audienceFilter={audienceFilterObj} />
			</>
		)
	)
}

export default PlannerResultList