// @flow

import * as React from "react";
import {Redirect} from "react-router-dom";
import {
  Page,
  Grid,
  Card,
  Table,
  Icon,
  Dimmer,
  Button,
  Badge,
  Form
} from "tabler-react";

import {Button as ButtonAntd} from 'antd';

import kineticDB, {
  bulkcreate,
} from '../database/DoohData.db';

import {DatePicker} from 'antd'
import moment from 'moment'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import SiteWrapper from "../SiteWrapper.react";
import Pagination from "../components/Pagination"
import { userService, doohEstimationService, companyService, audienceService, doohService } from "../_services";
import ShareManage from "../Share/ShareManage";
import { formatNumber } from "../_helpers";
import DoohPlanInfoCPM from "./Dooh.Plan.InfoCPM.react";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const monthFormat = 'YYYY-M';
const monthFormatFE = 'MMMM YYYY';
const today = new Date()
const todayStr = (today.getFullYear('Y').toString())+ '-' + (today.getMonth()+1)

const db = kineticDB('kineticDB', {
  doohData: `++id`,
});

class DoohPlanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      userInfo: [],
      doohPlans: [],

      activePage: 1,
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      maxPageRangeDisplayed: 5,
      offsetQuery: 0,
      sendingQuery: false,
      querySearch: "",
      dateSearch: todayStr,
      error: null,
      isAdmin: false,
      isKineticUser: true,
      hasRealDooh: true,
      isLoading: true,

      deletedPlan: false,
      deletingPlanId: null,
      deletingPlanName: "",
    };

    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this)
    this.exportAsset = this.exportAsset.bind(this)
  }

  timer = null

  componentWillMount(){
    userService.checkProfile()
  }

  componentDidMount() {
    this.preloadDoohData();
    this.getLocalUserInfo();
    this.setState({
      user: JSON.parse(localStorage.getItem("user")),
      doohPlans: [],
    });
    this.loadData(this.state.dateSearch, "%2A", this.state.offsetQuery, this.state.itemsCountPerPage, true);
  }

  getLocalUserInfo(){
    if(localStorage.getItem("type") === "admin" || localStorage.getItem("type") === "user"){
        this.setState({
          isKineticUser: true
        })
    }
    else{
        this.setState({
          isKineticUser: false
        })
    }

    if(localStorage.getItem("type") === "admin"){
      this.setState({
        isKineticUser: true,
        isAdmin: true
      })
    }

    const hasRealDooh = companyService.getUserAllowedModule('realdooh')
    this.setState({
      hasRealDooh
    })
  }

  preloadDoohData(){
  
    doohService.getAllDooh().then(
      result => {
        result.map((el) => {
          el.idKinFace = el.idKinFrame
          return el.key = el.id;
        });

        bulkcreate(db.doohData, result, true);
      },
    )
  }

  loadData(date, query, offset, limit, desc){
    doohEstimationService.getPlansFindQuery(date, query, offset, limit, desc).then(
      result => {
        this.setState({
          doohPlans: result.content,
          totalItemsCount: result.totalElements,
          isLoading: false,
        })
      },
      error => {
        this.setState({
          error,
          isLoading: false
        })
      }
    );
  }

  handlePageChange = (pageNumber) => {
    let offset = pageNumber-1
    this.setState({
      activePage: pageNumber,
      offsetQuery: offset,
      isLoading: true
    });

    this.loadData(this.state.dateSearch, this.state.querySearch, offset, this.state.itemsCountPerPage, true);
  }

  handleChangeQuery(ev) {
    this.setState({
      querySearch: ev.target.value
    })
    if(this.state.sendingQuery) {
      clearTimeout(this.state.sendingQuery);
    }

    this.setState({
      sendingQuery: setTimeout(this.handleQuery, 1000),
      isLoading: true
    })
  }


  handleQuery = e => {
    this.setState({
      activePage: 1,
      offsetQuery: 0,
    }, () => {
      this.loadData(this.state.dateSearch, this.state.querySearch, this.state.offsetQuery, this.state.itemsCountPerPage, true);
    });
    this.setState({sendingQuery: false})
  }

  handleChangeDate(momObj) {
    let dateStr;
    if(momObj === null){
      dateStr = todayStr
    }
    else{
      dateStr = moment(momObj).format()
      dateStr = dateStr.split('T')[0]
      dateStr = dateStr.split('-')[0] + '-' + parseInt(dateStr.split('-')[1])
    }

    this.setState({
      dateSearch: dateStr,
      activePage: 1,
      offsetQuery: 0,
      isLoading: true
    }, this.loadData(dateStr, this.state.querySearch, 0, this.state.itemsCountPerPage, true))
  }


  toggleModalDelete(deletingPlanId = null, deletingPlanName = "") {
    this.setState(prevState => ({
      isOpenModalDelete: !prevState.isOpenModalDelete,
      deletedPlan: false
    }));

    if(deletingPlanId){
      this.setState({deletingPlanId, deletingPlanName})
    }
  }

  onClickDelete(ev){
    let id = this.state.deletingPlanId;
    if(id){
      doohEstimationService.deleteSearch(id).then(
          result => {
              this.setState({
                  deletedPlan: true,
                  deletingPlanName: ""
              },()=>this.handlePageChange(this.state.activePage))
          },
          error => {
              console.error(error);
          }
      );
    }
  }

  exportAsset(){
    doohEstimationService.exportAssetXls();
    toast.info("Exporting asset data...")
  }

  onClickDuplicate(id){
    doohEstimationService.duplicateSearch(id).then(
      result => {
        this.setState({
          // id: result.id,
          loading: false,
        },()=>this.handlePageChange(this.state.activePage))
      },
      error => {
        this.setState({error:true})
        console.error(error);
      }
    );
  }

  itemNotFound(){
    if(this.state.totalItemsCount<=0 && this.state.error === null){
      return(
        <Card.Alert color="secondary">
          No plan found
        </Card.Alert>
      )
    }
  }

  errorLoad(){
    if(this.state.error !== null){
      return(
        <Card.Alert color="danger">
          Error: "{this.state.error.toString()}"
        </Card.Alert>
      )
    }
  }

  render() {
    const {isAdmin} = this.state

    if(this.state.error || (!this.state.isKineticUser && !this.state.hasRealDooh)){
      return <Redirect
          to={{ pathname: "/404", state: { from: this.props.location } }}
      />
    }

    const options = (
      <React.Fragment>
        {isAdmin && 
          <ButtonAntd className="mr-4 mt-1 text-primary" type="link" href="/asset/import/dooh" icon={<DownloadOutlined/>}>Import Asset</ButtonAntd>
        }
        <ButtonAntd className="mr-4 mt-1 text-primary" type="link" icon={<UploadOutlined/>} onClick={this.exportAsset}>Export Asset</ButtonAntd>
        <DoohPlanInfoCPM buttonType="link" buttonSize="middle" buttonClassName="text-primary mt-1" />
        <div hidden={this.state.querySearch !== ""}>
          <DatePicker
            className="ml-1 mr-1"
            size="large"
            defaultValue={moment(this.state.dateSearch, monthFormat)}
            value={moment(this.state.dateSearch, monthFormat)}
            format={monthFormatFE}
            picker="month"
            onChange={(ev, value) => this.handleChangeDate(ev)}
            style={{ minWidth: 162}}
          />
        </div>
        <div id="search-plan">
          <Form.Input icon="search" placeholder="Search plan" onChange={ev => this.handleChangeQuery(ev)} style={{height: "40px"}} />
        </div>
        <Button className="ml-1" color="primary" RootComponent="a" href="/dooh/plan/new">New proposal</Button>

        <Modal isOpen={this.state.isOpenModalDelete} toggle={this.toggleModalDelete}>
          <ModalHeader toggle={this.toggleModalDelete}>Delete plan</ModalHeader>
          <ModalBody>
              <div className="row">
                  <div className="col p-4">
                      {!this.state.deletedPlan ?
                          <p>Are you sure you want to delete the plan <strong>{this.state.deletingPlanName}</strong>?<br/>(This action is irreversible)</p>
                          : <p className="text-success">Plan successfully deleted!</p>
                      }
                  </div>
              </div>
          </ModalBody>
          {!this.state.deletedPlan ?
              <ModalFooter>
                  <Button color="danger" onClick={this.onClickDelete} disabled={this.state.deletedPlan}>Delete</Button>{' '}
                  <Button color="secondary" onClick={this.toggleModalDelete}>Close</Button>
              </ModalFooter>
              :
              <ModalFooter>
                  <Button color="primary" onClick={this.toggleModalDelete}>Close</Button>
              </ModalFooter>
          }
        </Modal>

      </React.Fragment>
    );

    return (
      <SiteWrapper {...this.state}>
        <Page.Content>
          <Page.Header
            title="ADVANCED DOOH Proposal"
            // subTitle={this.state.totalItemsCount + " found"}
            options={options}
          />
          <Grid.Row cards deck>
            <Grid.Col sm={12}>
              <Card>
                <Dimmer active={this.state.isLoading} loader={this.state.isLoading}>
                  <Table striped={true}>
                    <Table.Header>
                      <Table.ColHeader>Proposal Name</Table.ColHeader>
                      <Table.ColHeader>Owner</Table.ColHeader>
                      <Table.ColHeader>Created</Table.ColHeader>
                      {/* <Table.ColHeader>Update at</Table.ColHeader> */}
                      <Table.ColHeader alignContent="center">Audience</Table.ColHeader>
                      <Table.ColHeader alignContent="center">Budget</Table.ColHeader>
                      <Table.ColHeader alignContent="center">OTS</Table.ColHeader>
                      <Table.ColHeader alignContent="center">Action</Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                      {this.state.doohPlans.map((c, index) => {
                        const createdAt = c.createdAt ? (c.createdAt.split('T')[0] ? c.createdAt.split('T')[0] : '-') : '-'
                        const updatedAt = c.updatedAt ? (c.updatedAt.split('T')[0] ? c.updatedAt.split('T')[0] : '-') : '-'
                        const size = c.ambiences.length > 0 ? c.ambiences.length : <Badge color="success">NEW</Badge>
                        return <Table.Row key={index}>
                          <Table.Col>{c.name}</Table.Col>
                          <Table.Col>{c.owner}</Table.Col>
                          <Table.Col>{createdAt}</Table.Col>
                          {/* <Table.Col>{updatedAt}</Table.Col> */}
                          <Table.Col alignContent="center">
                            <Badge color={c.audienceId === null ? "secondary" : "info"}>
                              {c.audienceId === null ? "OFF" : "ON"}
                            </Badge>
                          </Table.Col>
                          <Table.Col alignContent="center">{c.budget ? (formatNumber(c.budget, true, true, false) + " €") : "-"}</Table.Col>
                          <Table.Col alignContent="center">{c.totalOTS > 0 ? formatNumber(Math.round(c.totalOTS), true, true, false) : "-"}</Table.Col>

                          <Table.Col alignContent="center">
                            <a className="link-nodecoration" href={'/dooh/plan/'+c.id+'/'} title="Open plan">
                              <Icon link={true} name="list" className="m-2 fa-lg link-nodecoration" />
                            </a>
                            {/* <Popconfirm
                              title="Are you sure duplicate this plan?"
                              onConfirm={() => this.onClickDuplicate(c.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <span href="#" title={"Duplicate " + c.name}>
                                <Icon link={true} name="copy" className="m-2 fa-lg link-nodecoration" />
                              </span>
                            </Popconfirm> */}

                            <span href="#" onClick={() => this.toggleModalDelete(c.id, c.name)} title={"Delete " + c.name}>
                              <Icon link={true} name="trash" className="m-2 fa-lg link-nodecoration" />
                            </span>

                            <ShareManage id={c.id} name={c.name} resourceType="sightline">
                              <span className="link-nodecoration" title="Share plan...">
                                <Icon link={true} name="link" className="m-2 fa-lg link-nodecoration" />
                              </span>
                            </ShareManage>

                          </Table.Col>

                        </Table.Row>

                      })}
                    </Table.Body>
                  </Table>
                  {this.itemNotFound()}
                  {this.errorLoad()}
                </Dimmer>
              </Card>

            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              {
                !this.state.isLoading ?
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={this.state.totalItemsCount}
                    maxPageRangeDisplayed={this.state.maxPageRangeDisplayed}
                    handlePageChange={this.handlePageChange}
                  /> : ''
              }
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default DoohPlanner;
