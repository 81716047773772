// import * as React from "react";
import React, { useContext, useState, useEffect, useRef } from 'react';
import { formatNumber } from '../_helpers'
import _ from 'lodash'
import { Button, Icon, Form as FormTabler} from "tabler-react"
import { Table, Input, Typography, Form, Dropdown, List, Checkbox, Button as ButtonAntd, Modal, Badge } from 'antd';
import styles from 'antd/dist/antd.css';
import DoohPlanInfoCPM from './Dooh.Plan.InfoCPM.react'
import {SettingOutlined, ThunderboltOutlined, DotChartOutlined} from '@ant-design/icons'
import HeatMapTableAudience from '../Planner/Planner.HeatMapTableAudience.react';
import { doohEstimationService } from '../_services';
import { toast } from 'react-toastify';
const { Text } = Typography;

function renderPercentage(value, decimal = false){
  return (
    <span>{ formatNumber(value, false, !decimal, true) }</span>
  )
}

const EditableContext = React.createContext();

const renderBody = ({ index, ...props }, columns) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx]
            }
          })}
        </tr>
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  editableType = 'text',
  restorableWith = null,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const restoreEditByField = () => {
    const ev = {
      target: {
        id: dataIndex,
        forceReset: true
      }
    }
    setEditing(true);
    form.setFieldsValue({
      [dataIndex]: null,
    });

    setEditing(false);

    save(ev);
  }

  const save = async (ev) => {
    const {id, forceReset = false} = ev.target

    try {
      const values = await form.validateFields();
      
      if(forceReset === true){
        values[id] = null;
      }
      else if(editableType === 'perc'){
        values[id] = values[id].toString().replace(',', '.')
        values[id] = parseFloat(values[id])
        values[id] /= 100
      }
      else if(editableType === 'integer'){
        values[id] = Math.round(values[id])
      }
      else if(editableType === 'float'){
        values[id] = values[id].toString().replace(',', '.')
      }

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
            // type: editableType !== 'text' ? "number" : editableType
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} defaultValue={0} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          // paddingRight: 24,
          cursor: 'pointer',
          // boxShadow: "0 0 2px #ccc"
          // backgroundColor: "#1890ff11"
        }}
      >
        <span onClick={toggleEdit}>
          {children[1]===null ? '0' : children}
          <Icon name="edit-3" className="text-muted ml-2"/>
        </span>
        {
          (restorableWith !== null && children[1] !== restorableWith) &&
           (
            <Button color="link" size="small" onClick={restoreEditByField}>
              <Icon title="Restore" name="rotate-ccw" className="text-muted ml-2" />
            </Button>
          )
        }
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class DoohQuotes extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      columnsT: [
        {
          title: 'SELECTION',
          dataIndex: 'name',
          width: 350,
          fixed: 'left',
          editable: false,
          visible: true,
          render: (value, record) => (
            <>
              <strong>{value}</strong>
              {this.props.audienceFilter && this.renderHeatmap(null, record)}
              {record.isCoverage && <Badge className='ml-2' count="Proximity" size="default" style={{backgroundColor: "#fff", color: "#5eba00", boxShadow: "0 0 0 1px #5eba00 inset"}}/>}
            </>
          )
        },
        {
          title: 'DAILY OTS',
          width: 130,
          dataIndex: 'totalDayOts',
          visible: true,
          align: "center",
          render: (value, record) => <span>{formatNumber(value, true, true, false)}</span> 
        },
        // {
        //   title: 'OTS BASKET EFFECTIVE',
        //   width: 130,
        //   dataIndex: 'totalDayOts',
        //   visible: true,
        //   align: "center",
        //   render: (value, record) => {
        //     let effectiveValue = 0;
        //     if(record.totalDayOtsPercentage !== null){
        //       effectiveValue = value * record.totalDayOtsPercentage;
        //     }
        //     else {
        //       effectiveValue = value;
        //     }
        //     return formatNumber(effectiveValue, true, true, false);
        //   }
        // },
        {
          title: 'REFERENCE',
          width: 130,
          dataIndex: 'totalDayOtsPercentage',
          editable: true,
          editableType: 'perc',
          restorableWith: "100%",
          visible: false,
          align: "center",
          render: value => value ? renderPercentage(value) : "100%"
        },
        {
          title: 'DAYS',
          dataIndex: 'daysOnair',
          width: 80,
          align: "center",
          visible: true,
          editable: true,
          editableType: 'integer'
        },
        {
          title: 'HOURS',
          dataIndex: 'hoursOnair',
          width: 80,
          align: "center",
          visible: true,
          editable: true,
          editableType: 'integer'
        },
        { title: 'OTS PER HOURS', width: 130, dataIndex: 'totalHoursOts', visible: false, align: "center", render: value => formatNumber(value, true, true, false) },
        { title: 'OTS PER DAYS', width: 130, dataIndex: 'otsPerDay', visible: false, align: "center", render: value => formatNumber(value, true, true, false)},
        { title: 'OTS MAX', width: 130, dataIndex: 'totalCampaignOts', visible: false, align: "center", render: value => formatNumber(value, true, true, false)},
        {
          title: 'CPM SELL',
          dataIndex: 'cpm',
          width: 130,
          align: "center",
          visible: true,
          editable: true,
          editableType: 'float'
        },
        {
          title: 'CPM BUY',
          dataIndex: 'cpmBuying',
          width: 130,
          align: "center",
          visible: false,
          editable: true,
          editableType: 'float'
        },
        { title: 'BUDGET MAX', width: 130, dataIndex: 'investment', visible: false, align: "center", render: value => formatNumber(value, true, true, false) },
        {
          title: 'AUTOMATIC BUDGET%',
          width: "380px",
          dataIndex: 'allocationPercentage',
          visible: true,
          align: "center",
          render: (value, record) => (
            record.allocationPercentageCustom === null ? renderPercentage(value) : (<span className='text-muted'>{renderPercentage(value)} (disabled)</span>)
          )
        },
        {
          title: 'CUSTOM BUDGET%',
          width: 180,
          dataIndex: 'allocationPercentageCustom',
          editable: true,
          editableType: 'perc',
          restorableWith: 'Auto',
          visible: true,
          align: "center",
          render: value => value !== null ? renderPercentage(value) : "Auto"
        },
        {
          title: 'BUDGET CLIENT',
          width: 130,
          dataIndex: 'budgetAllocated',
          visible: true,
          align: "center",
          render: value => formatNumber(value, true, true, false)
        },
        {
          title: 'OTS PAID',
          width: 130,
          dataIndex: 'totalOts',
          visible: true,
          align: "center",
          render: (value, record) => {
            const {totalCampaignOts} = record;
            const isOver = (value > totalCampaignOts)
            return(
              <span className={isOver ? 'text-danger' : 'text-success'}>
                {formatNumber(value, true, true, false)}
              </span>
            )
          }
        },
        { title: 'SHARE VISIBILITY', width: 130, dataIndex: 'visibilityPercentage', align: "center", visible: false, render: value => renderPercentage(value) },
        { title: '% FREE', width: 130, dataIndex: 'discountPercentage', editable: true, editableType: 'perc', align: "center", visible: false, render: value => renderPercentage(value)},
        { title: 'OTS FREE', width: 130, dataIndex: 'totalOtsDiscount', visible: false, editable: true, align: "center", render: value => formatNumber(value, true, true, false) }, //non e un editable in realta
        {
          title: 'BUDGET BUY',
          width: 130,
          dataIndex: 'budgetBuying',
          key: 'budgetBuy',
          visible: false,
          align: "center",
          render: value => formatNumber(value, true, true, false)
        },
        {
          title: 'MARGIN',
          width: 130,
          dataIndex: 'margin',
          key: 'margin',
          visible: false,
          align: "center",
          render: (value, {budgetBuying, budgetAllocated}) => formatNumber((budgetAllocated - budgetBuying), true, true, false)
        },
        {
          title: '% MARGIN',
          width: 130,
          dataIndex: 'marginPerc',
          key: 'marginPerc',
          visible: false,
          align: "center",
          render: (value, {budgetBuying, budgetAllocated}) => renderPercentage(((budgetAllocated - budgetBuying) / budgetAllocated))
        },
      ],
      columnsCount: 8,

      visibleDropdownColumns: false,

      idEstimation: null,
      ambiences: [],
      budget: null,

      visibleModalSetUp: false,
      quickCPM: '',
      quickCPMBuying: '',
      quickDays: '',
      quickHours: '',

      loading: true
    }

    this.getHistoriesByIdsAndOpenHeatmap = this.getHistoriesByIdsAndOpenHeatmap.bind(this);

  }

  componentWillMount(){
    let {ambiences, budget, idEstimation, loading} = this.props

    ambiences = ambiences.map((item, index) => {
      item.key = index
      return item
    })

    this.setState({
      ambiences,
      budget,
      idEstimation,
      loading: loading || false
    })
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.ambiences!==this.props.ambiences){
      let {ambiences} = nextProps

      ambiences = ambiences.map((item, index) => {
        item.key = index
        return item
      })

      this.setState({
        ambiences: nextProps.ambiences
      })
    }

    if(nextProps.budget!==this.props.budget){
      this.setState({
        budget: nextProps.budget
      })
    }

    if(nextProps.idEstimation!==this.props.idEstimation){
      this.setState({
        idEstimation: nextProps.idEstimation
      })
    }

    if(nextProps.loading!==this.props.loading){
      this.setState({
        loading: nextProps.loading,
      })
    }
  }

  // blur or press enter inside table
  handleSave = (row) => {
    const newData = [...this.state.ambiences];
    const index = newData.findIndex((item) => row.key === item.key);
    const {cpm, daysOnair, hoursOnair, discountPercentage} = row

    if(cpm > 0 || daysOnair>0 || hoursOnair>0 || discountPercentage >= 0){
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      this.setState({
        ambiences: newData,
      });
    }
  };

  handleChangeEstimation = () => {
    const {budget, ambiences} = this.state
    if(budget && budget > 10000000){
      toast.error('The budget must be less than 10.000.000€');
      return
    }
    this.props.handleUpdateAmbiences(ambiences, budget)
  }

  handleChangeBudgetPartial = (ev) => {
    const {value} = ev.target
    this.setState({
      budget: value
    })
  }

  getSumsOts = (pageData) => {
    let sumTotalOts = 0;
    let sumTotalOtsDiscount = 0;
    let otsPaidSum = 0
    let otsFreeSum = 0
    let otsPaidTotal = 0
    let sumCampaignOts = 0

    pageData.forEach(({ totalCampaignOts, totalOts, totalOtsDiscount }) => {
      sumTotalOts += Math.round(totalOts);
      sumTotalOtsDiscount += Math.round(totalOtsDiscount);

      sumCampaignOts += totalCampaignOts

      otsPaidSum = sumTotalOts
      otsFreeSum = sumTotalOtsDiscount
      otsPaidTotal = otsPaidSum + otsFreeSum
    });

    let totalShare = otsPaidTotal / sumCampaignOts
    totalShare = isNaN(totalShare) ? 0 : totalShare

    return({
      otsPaidSum,
      otsFreeSum,
      otsPaidTotal,
      totalShare
    })
  }

  handleMenuClick(e) {
    console.log('click', e);
  }

  handleVisibleChangeDropdownColumns = flag => {
    this.setState({ visibleDropdownColumns: flag });
  };

  handleChangeToggleColumn = (ev, dataIndex) => {
    const {checked} = ev.target
    let {columnsT} = this.state

    let columnsCount = 0

    columnsT = columnsT.map(col => {
      if(col.dataIndex === dataIndex){
        col.visible = checked
      }
      if(col.visible){
        columnsCount++
      }
      return col
    })

    this.setState({
      columnsT,
      columnsCount
    })
  }

  onClickQuickSetUp(visibleModalSetUp) {
    if(!visibleModalSetUp){
      this.setState({
        quickCPM: '',
        quickCPMBuying: '',
        quickDays: '',
        quickHours: ''
      })
    }
    this.setState({
      visibleModalSetUp
    })
  }

  handleChangeCPM = ev => {
    let {value} = ev.target
    value = value.toString().replace(',', '.')
    this.setState({
      quickCPM: value
    })
  }

  handleChangeCPMBuying = ev => {
    let {value} = ev.target
    value = value.toString().replace(',', '.')
    this.setState({
      quickCPMBuying: value
    })
  }

  handleChangeDays = ev => {
    let {value} = ev.target
    value = isNaN(value) ? value : Math.round(value)
    this.setState({
      quickDays: value
    })
  }

  handleChangeHours = ev => {
    let {value} = ev.target
    value = isNaN(value) ? value : Math.round(value)
    this.setState({
      quickHours: value
    })
  }

  handleModalOkSetUp = ev => {
    const {quickCPM, quickCPMBuying, quickDays, quickHours} = this.state
    if(this.isValidatedQuickSetUp()){
      let {ambiences, budget} = this.state
      ambiences = ambiences.map(amb => {
        amb.cpm = quickCPM
        amb.cpmBuying = quickCPMBuying
        amb.hoursOnair = quickHours
        amb.daysOnair = quickDays
        return amb
      })
      this.setState({
        visibleModalSetUp: false
      })
      this.props.handleUpdateAmbiences(ambiences, budget)
    }
  }

  isValidatedQuickSetUp = () => {
    const {quickCPM, quickCPMBuying, quickDays, quickHours} = this.state
    const areNotEmpty = quickCPM !== '' && quickDays !== '' && quickHours !== ''
    return (!isNaN(quickDays) && !isNaN(quickHours) && !isNaN(quickCPM)  && !isNaN(quickCPMBuying) && areNotEmpty)
  }

  getHistoriesByIdsAndOpenHeatmap = (historiedId = [], selectionName) => {
    doohEstimationService.getHistoriesByIds(this.state.idEstimation, historiedId).then(
      result => {
        const idKinFrames = result.map(({idKinFrame, parentFrame}) => parentFrame !== null ? parentFrame : idKinFrame);
        const titleHeatmap = `Heatmap of ${selectionName} (${idKinFrames.length} frames)`;
        Modal.info({
            title: titleHeatmap,
            closable: true,
            width: 850,
            content: (
              <HeatMapTableAudience faceIds={idKinFrames} audienceFilter={this.props.audienceFilter} type='dooh'/>
            ),
          });
      },
      error => {
        console.error("Error: " + error);
      }
    );
  }

  renderHeatmap = (faceIds = null, record = null) => {
    const {histories, name} = record;

    return(
      <ButtonAntd
        type="link"
        icon={<DotChartOutlined/>}
        onClick={ () => {
          this.getHistoriesByIdsAndOpenHeatmap(histories, name);
        }}
      />
    )
  }

  render(){
    let { ambiences, budget, columnsT, columnsCount, visibleDropdownColumns, quickCPM, quickCPMBuying, quickDays, quickHours, loading } = this.state
    const {readonly} = this.props || false
    ambiences = ambiences.filter(el => el.visible)
    const {otsFreeSum, otsPaidTotal, totalShare} = this.getSumsOts(ambiences)
    const isEdit = (budget !== this.props.budget) || !_.isEqual(ambiences, this.props.ambiences)

    const renderHeader = (props, columns) => {
      return (
        <tr>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
            return null;
          }).filter(child => child !== null)}
        </tr>
      );
    }

    const components = {
      header: {
        row: (props) => renderHeader(props, columns)
      },
      body: {
        row: (props) => renderBody(props, columns),
        cell: EditableCell,
      },
    }

    const columns = columnsT.map((col) => {
      if (!col.editable || readonly) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          editableType: col.editableType,
          restorableWith: col.restorableWith,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      }
    })

    // { title: 'BUDGET MAX', width: 130, dataIndex: 'investment', render: value => formatNumber(value, true, true, false) },
    const menu = (
      <div className="bg-white border shadow">
        <List
          size="small"
          // bordered
          dataSource={columnsT}
          renderItem={item => <List.Item>
              <Checkbox
                key={item.dataIndex}
                checked={item.visible}
                // disabled={item.editable && item.dataIndex!=='discountPercentage' && item.dataIndex!=='cpmBuying' && item.dataIndex!=='totalDayOtsPercentage'}
                disabled={item.editable && item.dataIndex!=='cpmBuying' && item.dataIndex!=='totalDayOtsPercentage'}
                onChange={(ev) => this.handleChangeToggleColumn(ev, item.dataIndex)}
                >
                  {item.title}
              </Checkbox>
            </List.Item>
          }
        />
      </div>
    );

    return(
      <div className={styles}>
        <div className="row">
          <div className="col-4 p-0">
            <Dropdown
              overlay={menu}
              onVisibleChange={this.handleVisibleChangeDropdownColumns}
              visible={visibleDropdownColumns}
            >
              <ButtonAntd>
                <SettingOutlined /> Show/Hide Columns
              </ButtonAntd>
            </Dropdown>

            {
              !readonly &&
              <>
                <ButtonAntd onClick={() => this.onClickQuickSetUp(true)} className="ml-2">
                  <ThunderboltOutlined /> Quick setup
                </ButtonAntd>
                <DoohPlanInfoCPM />
              </>
            }
          </div>
          <div className="col-3">
            <FormTabler.Group>
              <FormTabler.InputGroup>
                <FormTabler.InputGroupPrepend>
                  <FormTabler.InputGroupText>
                    Budget
                  </FormTabler.InputGroupText>
                </FormTabler.InputGroupPrepend>
                <FormTabler.Input name='budget' type="number" placeholder='Budget' value={budget} onChange={this.handleChangeBudgetPartial} readOnly={readonly} max={10000000} />
                <FormTabler.InputGroupAppend>
                  <FormTabler.InputGroupText>
                    <Icon prefix="fa" name="euro" />
                  </FormTabler.InputGroupText>
                </FormTabler.InputGroupAppend>
              </FormTabler.InputGroup>
            </FormTabler.Group>
          </div>

          <div className={(readonly ? 'offset-1 col-4 pr-0' : 'col-4') + " align-self-center text-right"}>
            <FormTabler.Group>
              <FormTabler.InputGroup>
                <FormTabler.InputGroupPrepend>
                  <FormTabler.InputGroupText>
                    Total OTS:
                  </FormTabler.InputGroupText>
                </FormTabler.InputGroupPrepend>
                <FormTabler.Input name='ots-total' readOnly placeholder='-' value={formatNumber(otsPaidTotal, true, true, false) + ' (' + formatNumber(totalShare, false, true, true) + ')'} />
                <FormTabler.InputGroupAppend>
                  <FormTabler.InputGroupText>
                    {(formatNumber(otsFreeSum, true, true, false) + ' for Free')}
                  </FormTabler.InputGroupText>
                </FormTabler.InputGroupAppend>
              </FormTabler.InputGroup>
            </FormTabler.Group>
          </div>

          {
            !readonly &&
              <div className="col-1 pr-0">
                <Button color="primary" block onClick={this.handleChangeEstimation} disabled={!isEdit}>Save</Button>
              </div>
          }
          <div className="col-12 p-0">
            <Table
              loading={loading}
              size="small"
              bordered
              components={components}
              rowClassName={() => 'editable-row'}
              columns={columns}
              dataSource={ambiences}
              scroll={{ x: 2100 }}
              pagination={false}
              summary={pageData => {
                let sumOtsPerDay = 0;
                let sumCampaignOts = 0;
                let sumInvestment = 0;
                let sumAllocationPerc = 0;
                let sumAllocationPercCustom = 0;
                let sumAllocated = 0;
                let sumBudgetBuying = 0;
                let sumTotalOts = 0;
                let sumDiscountPercentage = 0;
                let sumTotalOtsDiscount = 0;

                let sumMarginBudget = 0;
                let totalMarginBudgetPerc = 0;

                pageData.forEach(({ otsPerDay, totalCampaignOts, investment, allocationPercentage, allocationPercentageCustom, budgetAllocated, budgetBuying, totalOts, visibilityPercentage, discountPercentage, totalOtsDiscount }) => {
                  let marginPartial = (budgetAllocated - budgetBuying);

                  sumOtsPerDay += otsPerDay;
                  sumCampaignOts += totalCampaignOts;
                  sumInvestment += Math.round(investment);
                  sumAllocationPerc += allocationPercentage;
                  sumAllocationPercCustom += ((allocationPercentageCustom !== null) ? allocationPercentageCustom : allocationPercentage);
                  sumAllocated += Math.round(budgetAllocated);
                  sumBudgetBuying += Math.round(budgetBuying);
                  sumTotalOts += Math.round(totalOts);

                  sumDiscountPercentage += discountPercentage;
                  sumTotalOtsDiscount += Math.round(totalOtsDiscount);

                  sumMarginBudget += marginPartial;
                });

                totalMarginBudgetPerc = (sumAllocated - sumBudgetBuying) / sumAllocated;

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell className="summary-table-cell-fix-left ant-table-cell-fix-left ant-table-cell-fix-left-last">
                        <Text strong></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={columnsT.filter((c, i) => c.visible && i > 0 && i < 6 ).length} />
                      <Table.Summary.Cell className={!columnsT[6].visible ? 'd-none' : 'text-center'}>
                        <Text strong>{formatNumber(sumOtsPerDay, true, true, false)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[7].visible ? 'd-none' : 'text-center'}>
                        <Text strong>{formatNumber(sumCampaignOts, true, true, false)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[8].visible ? 'd-none' : 'text-center'}>
                        <Text strong></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[9].visible ? 'd-none' : 'text-center'}>
                        <Text strong></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[10].visible ? 'd-none' : 'text-center'}>
                        <Text strong>{formatNumber(sumInvestment, true, true, false)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[11].visible ? 'd-none' : 'text-center'}>
                        <Text strong>{formatNumber(sumAllocationPerc, false, true, true)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[12].visible ? 'd-none' : 'text-center'}>
                        <Text strong>{formatNumber(sumAllocationPercCustom, false, true, true)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[13].visible ? 'd-none' : 'text-center'}>
                        <Text strong>{formatNumber(sumAllocated, true, true, false)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[14].visible ? 'd-none' : 'text-center'}>
                        <Text strong>{formatNumber(sumTotalOts, true, true, false)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[15].visible ? 'd-none' : 'text-center'}>
                        <Text strong></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[16].visible ? 'd-none' : 'text-center'}/>
                      <Table.Summary.Cell className={!columnsT[17].visible ? 'd-none' : 'text-center'}>
                        <Text strong>{formatNumber(sumTotalOtsDiscount, true, true, false)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[18].visible ? 'd-none' : 'text-center'}>
                        <Text strong>{formatNumber(sumBudgetBuying, true, true, false)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[19].visible ? 'd-none' : 'text-center'}>
                        <Text strong>{formatNumber(sumMarginBudget, true, true, false)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={!columnsT[20].visible ? 'd-none' : 'text-center'}>
                        <Text strong>{renderPercentage(totalMarginBudgetPerc)}</Text>
                      </Table.Summary.Cell>

                    </Table.Summary.Row>

                    <Table.Summary.Row>

                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </div>
        </div>

        <Modal
          title="Quick setup"
          visible={this.state.visibleModalSetUp}
          onOk={this.handleModalOkSetUp}
          okButtonProps={{ disabled: !this.isValidatedQuickSetUp() }}
          okText="Setup all"
          onCancel={()=>this.onClickQuickSetUp(false)}
        >
          <FormTabler.Group label="Insert values">
            <br/>
            <FormTabler.Input
              name="quick-days"
              label="DAYS *"
              placeholder="DAYS"
              onChange={this.handleChangeDays}
              value={quickDays}
            />
            <FormTabler.Input
              name="quick-hours"
              label="HOURS *"
              placeholder="HOURS"
              onChange={this.handleChangeHours}
              value={quickHours}
            />
            <FormTabler.Input
              name="quick-cpm"
              label="CPM *"
              placeholder="CPM"
              onChange={this.handleChangeCPM}
              value={quickCPM}
            />
            <FormTabler.Input
              name="quick-cpm-buying"
              label="CPM Buying"
              placeholder="CPM Buying"
              onChange={this.handleChangeCPMBuying}
              value={quickCPMBuying}
            />
            <br/>
            <em>* Required fields</em>
          </FormTabler.Group>
        </Modal>

      </div>
    )
  }
}

export default DoohQuotes;
